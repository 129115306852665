import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {
  Box,
  Button,
  FlatList,
  HStack,
  Pressable,
  Spacer,
  Stack,
  Text,
  useMediaQuery,
  View,
  VStack,
} from 'native-base';
import { Collapse, Tooltip } from 'antd';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';
import {
  FormContext,
  FormViewType,
  IFormCommonData,
  IFormComponentProps,
} from '../../CustomWrapper/CustomWrapper';
import {
  acceptIgnorePatientReportedRecords,
  fhirAddOrUpdate,
  getActivities,
  getResourceContentWithHeaders,
} from '../../../../../../../services/CommonService/AidBoxService';
import FormComponentLoader from '../../CustomWrapper/FormComponentLoader/FormComponentLoader';
import {
  IAddOrUpdateVitalsState,
  IObservation,
  IVitalsComponentValue,
} from '../interfaces';
import {getDateStrFromFormat, getMomentObj, isFutureDate} from '../../../../../../../utils/DateUtils';
import {
  getFormattedVitalValue,
  getLastActivityRecord,
  getLastRecordText,
  getMaxAllowedDecimals,
  getRecordDataFormatted,
  setVitalValuePrecision,
  sortOldRecords,
} from './AddOrUpdateVitalsHelper';
import {Checkbox, DatePicker, Divider, Drawer} from 'antd';
import {useIntl} from 'react-intl';
import {
  BUTTON_TYPE,
  DIASTOLIC_BLOOD_PRESSURE_LOINC,
  DISPLAY_DATE_FORMAT,
  HTTP_ERROR_CODE,
  SYSTOLIC_BLOOD_PRESSURE_LOINC,
  WINDOW_EVENT_CODES,
} from '../../../../../../../constants';
import VitalInput from '../VitalInput/VitalInput';
import {Colors} from '../../../../../../../styles';
import {VitalType} from '../../../../../../TaskCard/VitalCaptureInput/VitalCaptureInput';
import {
  FHIR_RESOURCE,
  PatientRecordActions,
  RESOURCE_BLOCK_MESSAGE_ID,
} from '../../../../../../../constants/FhirConstant';
import { IVitalData } from '../../../../../../../Interfaces';
import FormComponentError from '../../CustomWrapper/FormComponentError/FormComponentError';
import {
  ADD_UPDATE_EVENTS,
  componentKeys,
  FormError,
  getHeaderButtons,
  tooltipInnerOverlayStyle,
} from '../../CustomWrapper/CustomComponentHelper';
import {DetailViewCollapse} from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailViewCollapse';
import {VitalsGraphView} from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/VitalsGraphView';
import {EmptyStateSvg} from '../../../../../../common/Svg';
import {EventBus} from '../../../../../../../utils/EventBus';
import {getBMIValue} from '../../../../../../../utils/vitalUnitConversions';
import PamiIconSvg from '../../../../../../common/Svg/PamiIconSvg';
import {DisplayText} from '../../../../../../common/DisplayText/DisplayText';
import {
  getDisplay,
  getEHRDisplay,
  getEnabledVitalListFromCapability,
  getEnabledVitals,
  getFHIRTemplate,
  getIndividualFHIRTemplate,
  getObservationFHIRBundle,
  getObservationResourceTemplate,
  getUnit,
  getVitalConfig,
  isVitalValueAvailable,
  Vital,
  VitalInputType,
} from '../../../../../../../utils/VitalUtils';
import CustomButton from '../../CustomWrapper/CustomButton/CustomButton';
import PatientReportedTableView from '../../CustomWrapper/PatientReportedTableView/PatientReportedTableView';
import SectionLoadingIndicator from '../../CustomWrapper/SectionLoadingIndicator/SectionLoadingIndicator';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useContainerDimensions } from '../../../../../../CustomHooks/ContainerDimensionHook';
import { cloneDeep } from 'lodash';
import Activities, { getGraphTitle, GRAPH_TYPES } from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/Activities/Activities';
import { ModalActionTitle } from '../../../../../../common/ModalActionTitle/ModalActionTitle';
import { getDateRange, getPatientData } from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/Activities/ActivityUtils';
import { activityFrequencyUnitCodes } from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/Activities/ActivitiesGraphView/ActivitiesGraphView';
import { CUSTOM_COMPONENT_STYLE } from '../../constants';
import DetailPreview, { ICustomComponentPreviewData } from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailPreview/DetailPreview';
import {v4 as uuidv4} from 'uuid';
import { RightOutlined } from '@ant-design/icons';
import { IFormValidationOutput } from '../../CustomWrapper/interfaces';
import { getOrganizationCapabilites } from '../../../../../TeamInbox/Conversations/MessagingContactDetails/ContactDetailsTabs/ActivityTimeLine/ActivityAdditionalInfo/CommonComponent/FormResponse/FormUtils';
import RecentReportedViewHeader from '../../../../../../common/RecentReportedViewHeader/RecentReportedViewHeader';
import { ClinicalSectionFilterTabs } from '../../../../../../PatientOmniView/Sections/ClinicalSection/ClinicalSectionFilterTabs/ClinicalSectionFilterTabs';
import { CINICAL_SECTIONS_FILTER_TABS, CINICAL_SECTIONS_FILTER_TAB_CODES, CINICAL_SECTIONS_VIEW_TABS, CLINICAL_SECTION_VIEW_TYPES } from '../../../../../../PatientOmniView/Sections/ClinicalSection/ClinicalSectionConstants';
import { ClinicalSectionViewToggle } from '../../../../../../PatientOmniView/Sections/ClinicalSection/ClinicalSectionFilterTabs/ClinicalSectionViewToggle';
import Feather from 'react-native-vector-icons/Feather';
import { ClinicalSectionListItem } from '../../../../../../PatientOmniView/Sections/ClinicalSection/ClinicalSectionListItem/ClinicalSectionListItem';
import { getFormattedVitalData } from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/DetailTableUtils';
import { getTrendViewGraphData } from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/VitalsGraphView/VitalsGraphUtils';
import { getEhrConfig } from '../../../../../../../utils/capabilityUtils';
import { StyleSheet } from 'react-native';

const AddOrUpdateVitals = (props: IFormComponentProps) => {
  const intl = useIntl();
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const capabilities = contextData.capabilities || props?.options?.capabilities;
  const isPatientNote = contextData.formContext === FormContext.patientNotes;
  const isPatientCarePlan = contextData.formContext === FormContext.patientCarePlan;
  const isPatientProfile = contextData.formContext === FormContext.patientProfile;
  const isRecentReported = contextData.formContext === FormContext.recentReportedView;
  const isCareProgramEnabled = props?.options?.isCareProgramEnabled;
  const isSidecarContext = props?.options?.isSidecar || false;
  const componentRef = useRef();
  const organizationCapability = getOrganizationCapabilites(
    contextData.organizationCapability,
    FHIR_RESOURCE.CLINICAL_DATA
  );
  const isPreviewMode = contextData.isPreviewMode || false;
  const isAllowToShare = props?.component?.allowToShare &&  props?.component?.allowToShare === false ? false : true;
  const fetchDataForPreview = contextData.fetchDataForPreview || false;
  const { width } = useContainerDimensions(componentRef);
  const [isMobileScreen] = [width <= 696];
  const isPatientForm = !contextData.backgroundSaveEnabled;
  const [componentValue, setComponentValue] = useState<IVitalsComponentValue>(
    props.component?.selectedValue || {
      observations: [],
      effectiveDateTime: getMomentObj(new Date()).toISOString(),
    }
  );

  const ehrConfig = getEhrConfig(contextData?.locationId, '');

  const [componentState, setComponentState] = useState<IAddOrUpdateVitalsState>(
    {
      loading: false,
      collapsed: contextData.defaultCollapse,
      showSavingIndicator: false,
      editModalVisible: false,
      editModalCollapse: false,
      oldRecords: [],
      formError: contextData.formError,
      showPatientReportedRecords: false,
      patientReported: [],
      selectedVitalsForBulkActions: [],
      showGraphs:false,
      activityData:[],
      activePanels: isCareProgramEnabled ? [] : [componentKeys.VITALS],
      seletedFilterTab: CINICAL_SECTIONS_FILTER_TAB_CODES.ALL,
      selectedViewType: CLINICAL_SECTION_VIEW_TYPES.GRAPH,
      allRecords: [],
      formattedVitalData: []
    }
  );

  const { Panel } = Collapse;

  const activityTypeList =  [GRAPH_TYPES.Calories, GRAPH_TYPES.Step, GRAPH_TYPES.Time]
  const validateData = (currentData: IVitalsComponentValue): IFormValidationOutput => {
    // If no patient id is not found means it's lead and for lead, the section is not applicable. So validation should always return true
    if (componentState.formError === FormError.noPatientIdFound) {
      return {isValid: true, message: ''};
    }
    setComponentState((prev) => ({...prev, showErrors: true}));
    if (!currentData.effectiveDateTime) {
      return {isValid: false, message: `${props.component.label}: Please fill all the mandatory fields`};
    }
    return {isValid: true, message: ''};
  };

  const enabledVitalList = getEnabledVitalListFromCapability(contextData?.locationId);

  const getVitalList = (): IVitalData[] => {
    const vitals = capabilities?.abilities?.allowedVitalList || [];
    return vitals.filter((item: { isHidden: any; }) => !item.isHidden);
  };

  useEffect(()=> {
    setComponentState((prev) => ({
      ...prev,
      oldRecords:
        componentState.seletedFilterTab ===
        CINICAL_SECTIONS_FILTER_TAB_CODES.RELEVANT
          ? filterByRelevantVitals(componentState.allRecords)
          : componentState.allRecords,
    }));
  }, [componentState.seletedFilterTab])

  const handleVitalChange = (vital: Vital, value?: string) => {
    const isBMIEnabled =
      getEnabledVitals(
        capabilities,
        props.component.enabledVitals
      ).includes(Vital.bmi) || false;
    setComponentValue((prev) => {
      const prevList = prev.observations;
      const vitalList = getVitalList();
      const unit = getUnit(vital, vitalList);
      const index = prevList.findIndex((observation) => {
        return observation.loinc === vital;
      });
      if (index === -1 && value) {
        // Add new
        prevList.push(getObservationResourceTemplate(vital, vitalList, value));
      } else if (!value) {
        // Remove if no value
        if (index !== -1) prevList.splice(index, 1);
      } else if (
        prevList[index].valueQuantity ||
        prevList[index].component?.length
      ) {
        // Update if already exists
        prevList[index].valueQuantity = {
          value,
          unit,
        };
      }
      if ((vital === Vital.height || vital === Vital.weight) && isBMIEnabled) {
        const heightIndex = prevList.findIndex((observation) => {
          return observation.loinc === Vital.height;
        });
        const weightIndex = prevList.findIndex((observation) => {
          return observation.loinc === Vital.weight;
        });
        if (heightIndex > -1 && weightIndex > -1) {
          const heightValue = prevList[heightIndex].valueQuantity?.value;
          const heightUnit = prevList[heightIndex].valueQuantity?.unit;
          const weightValue = prevList[weightIndex].valueQuantity?.value;
          const weightUnit = prevList[weightIndex].valueQuantity?.unit;
          if (heightValue && weightValue) {
            const bmi = getBMIValue(
              {value: `${heightValue}`, unit: heightUnit},
              {value: `${weightValue}`, unit: weightUnit}
            );

            const index = prevList.findIndex((observation) => {
              return observation.loinc === Vital.bmi;
            });
            const maxDecimals = getMaxAllowedDecimals(Vital.bmi, vitalList);
            const bmiValue = bmi.toFixed(maxDecimals);
            if (index === -1) {
              prevList.push(
                getObservationResourceTemplate(Vital.bmi, vitalList, bmiValue)
              );
            } else if (prevList[index].valueQuantity) {
              prevList[index].valueQuantity = {
                value: bmiValue,
              };
            }
          }
        }
      }
      return {
        ...prev,
        observations: [...prevList],
      };
    });
  };

  const getVitalInputs = (vitals: Vital[]): JSX.Element => {
    return (
      <Stack
        key={vitals.join(',')}
        paddingX={4}
        direction={isMobileScreen ? 'column' : 'row'}
        space={isMobileScreen ? 4 : 0}
      >
        {vitals.map((vital) => {
          const list = getVitalList();
          const selected = componentValue.observations.find((observation) => {
            return observation.loinc === vital;
          });
          let selectedValue = selected?.valueQuantity?.value;
          const vitalConfig = list.find((item) => {
            return item.loinc === vital;
          });
          const referenceRange = vitalConfig?.range || [];
          const defaultRange = {min: 0, max: 400};
          if (referenceRange.length === 0) {
            referenceRange.push(defaultRange);
          }
          let vitalType = VitalType.normal;
          if (vital === Vital.bloodPressure) {
            vitalType = VitalType.bloodPressure;
            const systolic = selected?.component?.find(
              (item) => item.code?.coding?.[0]?.code === SYSTOLIC_BLOOD_PRESSURE_LOINC
            );
            const diastolic = selected?.component?.find(
              (item) => item.code?.coding?.[0]?.code === DIASTOLIC_BLOOD_PRESSURE_LOINC
            );
            if (
              systolic?.valueQuantity?.value ||
              diastolic?.valueQuantity?.value
            ) {
              selectedValue = `${systolic?.valueQuantity?.value || ''}/${
                diastolic?.valueQuantity?.value || ''
              }`;
            }
          }
          if (vital === Vital.height) {
            vitalType = VitalType.heightWithFeetAndInches;
          }

          const display = getDisplay(vital, list);
          const lastRecordedText = getLastRecordText(
            componentState.oldRecords,
            vitalConfig
          ) as string;

          return (
            <div key={vital} className='page-break' style={{ maxWidth: 500, flex: 1 }}>
            <HStack space={2}>
              <VitalInput
                vital={{
                  value: selectedValue || selectedValue === 0 ? `${selectedValue}` : '',
                  min: referenceRange?.[0]?.min || 0,
                  max: referenceRange?.[0]?.max || 1000,
                }}
                decimalPrecision={vitalConfig?.allowedDecimalPlaces || 0}
                inputType={vitalConfig?.inputType || VitalInputType.numeric}
                label={display}
                unit={vitalConfig?.unit}
                displayUnit={vitalConfig?.displayUnit}
                disabled={props.disabled || componentState.showSavingIndicator}
                type={vitalType}
                lastRecordedText={lastRecordedText}
                possibleValues={vitalConfig?.possibleValues || []}
                format={vitalConfig?.format}
                loinc={vitalConfig?.loinc}
                onChange={(value) => {
                  handleVitalChange(vital, value);
                }}
              />
            </HStack>
            </div>
          );
        })}
      </Stack>
    );
  };

  const isCompactView = () => {
    if(isCareProgramEnabled){
      return componentState.selectedViewType === CLINICAL_SECTION_VIEW_TYPES.LIST;
    }

    return contextData.componentView === FormViewType.compact;
  };

  const isGraphView = () => {
    if(isCareProgramEnabled){
      return componentState.selectedViewType === CLINICAL_SECTION_VIEW_TYPES.GRAPH;
    }
    return contextData.componentView === FormViewType.graph;
  };

  const canAddNew = (): boolean => {
    // GLOBAL CHECK FROM EHR SETTINGS
    if (isPatientProfile && organizationCapability?.canCreate === false) {
      return false;
    }
    return (
      contextData.isBuilderMode ||
      ((capabilities?.abilities?.allowedOperations?.canCreate ||
        false) &&
        !props.disabled)
    );
  };

  const renderViewTabs = () => {
    return (
      <Stack direction={'row'} style={{flex: 1, justifyContent:'flex-end'}}>
        <ClinicalSectionViewToggle
          tabs={CINICAL_SECTIONS_VIEW_TABS}
          selectedTabCode={componentState.selectedViewType}
          onTabClick={(tab: string) => {
            setComponentState((prev) => ({
              ...prev,
              selectedViewType: tab,
            }));
          }}
        />
      </Stack>
    );
  }

  const isActivitiesEmpty = () => {
    const list = componentState.activityData.map(item => item.data);
    return list.every((item:any) => item?.timestamp === undefined || item?.timestamp === null)
  };

  const getDetailView = (
    isCompact: boolean,
    isGraph: boolean,
    isPatientReported: boolean
  ) => {
    const list = getVitalList();
    let enabledVitals = [] as Vital[];
    if (!isSidecarContext) {
      enabledVitals = getEnabledVitals(
        capabilities,
        props.component.enabledVitals
      );
    }

    const vitalList = getVitalList();

    if (!isSidecarContext && isCompact) {
      return (
        <VStack paddingX={2} paddingTop={3} paddingBottom={1}>

          {isCareProgramEnabled && (
            renderViewTabs()
          )}

          {componentState.oldRecords.length > 0 &&
            enabledVitals.map((vital, index) => {
              const display = getDisplay(vital, list);
              const vitalConfig = list.find((item) => {
                return item.loinc === vital;
              });
              const data = getLastRecordText(
                componentState.oldRecords,
                vitalConfig,
                true
              ) as {valueText: string; unit?: string, recordedDate: string};
              const graphData = getTrendViewGraphData(vital, componentState.formattedVitalData || [], vitalList);
              if (data?.valueText) {
                return (
                  <ClinicalSectionListItem
                    key={`${vital}_${index}`}
                    title={display}
                    lastRecordedOn={data.recordedDate}
                    highlightValue={data?.valueText}
                    code={vitalConfig?.loinc}
                    unit={vitalConfig?.loinc === Vital.height ? '' : data.unit}
                    vitalGraphData={graphData}
                  />
                );
              }
              return <></>;
            })}
          {/*
          NOTE: Code is kept for future reference
          {componentState.activityData.length > 0 && !isActivitiesEmpty() &&
            isCompact &&
            componentState.activityData.map((activity, index) => {
              const display = getGraphTitle(activity.type);
              const item = activity.data;
              return (
                <HStack key={`${activity.type}_${index}`} space={2}>
                  <Text size="smSemibold">{display}</Text>
                  <Spacer />
                  <VStack>
                    <Text>{`${item?.value} ${item?.unit}`}</Text>
                    {item?.timestamp && (
                      <Text color="gray.500" size="xsLight">
                        recorded on {getDateStrFromFormat(item?.timestamp,'MM/DD/YYYY')}
                      </Text>
                    )}
                  </VStack>
                </HStack>
              );
            })} */}
          {componentState.oldRecords.length === 0 &&
            componentState.patientReported.length === 0 &&
            isActivitiesEmpty() &&
            (isCompact ? (
              <View marginLeft={2} marginY={4}>
                <DisplayText
                  extraStyles={{
                    color: '#667085',
                  }}
                  textLocalId={'noVitalsData'}
                  textType="Text"
                />
              </View>
            ) : (
              <EmptyStateSvg titleId="noVitalsData" />
            ))}
          {componentState.patientReported.length > 0 && (
            <HStack marginBottom={4} marginTop={2}>
              <CustomButton
                onPress={() => {
                  setComponentState((prev) => ({
                    ...prev,
                    showPatientReportedRecords: true,
                  }));
                }}
                title={'View Patient Reported Records'}
              />
              <Spacer />
            </HStack>
          )}
        </VStack>
      );
    } else if (isSidecarContext || isGraph) {
      return (
        <>
          <View
            {...(isSidecarContext ? styles.sidecarGraphView : styles.defaultGraphView)}
          >
            {isCareProgramEnabled && !componentState.showGraphs && (
              renderViewTabs()
            )}
            <VitalsGraphView
              existingData={componentState.oldRecords}
              vitalList={getVitalList()}
              accountLocationUuid={contextData.locationId || props?.options?.locationId}
              contactId={contextData.contactId || props?.options?.contactId}
              {...(isSidecarContext ? { patientId: props?.options?.patientId } : {})}
              columnSize={(componentState.showGraphs || isSidecarContext) ? 1 : 2}
              isSmallView={(componentState.showGraphs || isSidecarContext) ? false : true}
              goals={props?.options?.goals}
            />

            {
              /*
                NOTE: REMOVED THIS BUT KEEPING CODE COMMENTED FOR FUTURE REFERENCE
                {!isCareProgramEnabled && (
                  <Activities
                    patientId={contextData.patientId}
                    accountLocationUuid={contextData.locationId}
                    contactId={contextData.contactId}
                  />
                )}
              */
            }

          </View>
        </>
      );
    } else if (isPatientReported) {
      let bulkAcceptActionName = 'Accept All';
      if (!componentState.selectedVitalsForBulkActions.length) {
        bulkAcceptActionName = '';
      } else if (componentState.selectedVitalsForBulkActions.length !== componentState.patientReported.length) {
        bulkAcceptActionName = 'Accept Selected';
      }
      return (
        <PatientReportedTableView
          showBulkAction={organizationCapability?.canCreate}
          dataSource={componentState.patientReported}
          selectedRecords={componentState.selectedVitalsForBulkActions}
          bulkActions={(
            <HStack height={28}>
              <Spacer />
              {organizationCapability?.canCreate && bulkAcceptActionName && (
                <CustomButton
                  title={bulkAcceptActionName}
                  isDisabled={componentState.showSavingIndicator || !componentState.selectedVitalsForBulkActions.length}
                  onPress={() => {
                    handlePatientReportedBulkActions(PatientRecordActions.accept);
                  }}
                />
              )}
            </HStack>
          )}
          onBulkCheckboxChange={(event: CheckboxChangeEvent) => {
            if (!organizationCapability?.canCreate) {
              return;
            }
            let selectedList: string[] = [];
            if (event.target.checked) {
              selectedList = componentState.patientReported.map((item) => item.id || '');
            }
            setComponentState((prev) => ({...prev, selectedVitalsForBulkActions: [...selectedList]}));
          }}
          rowViewForItem={(item: IObservation, index: number) => {
            const vitalConfig = getVitalConfig(
              item?.code?.coding?.[0]?.code as Vital,
              list
            );
            const vitalName = vitalConfig?.foldDisplay || vitalConfig?.display;
            const vitalFormattedData = getFormattedVitalValue(item, vitalConfig, true) as {value?: string, recordedDate?: string};
            const primaryId = item.id || '';
            return (
              <VStack key={item.id}>
                <HStack space={1} alignItems="start">
                  <View paddingRight={1}>
                    <Checkbox
                      onClick={() => {
                        const selectedList =
                          componentState.selectedVitalsForBulkActions;
                        const index = selectedList.indexOf(primaryId);
                        if (index !== -1) {
                          selectedList.splice(index, 1);
                        } else {
                          selectedList.push(primaryId);
                        }
                        setComponentState((prev) => ({
                          ...prev,
                          selectedVitalsForBulkActions: selectedList,
                        }));
                      }}
                      disabled={!organizationCapability?.canCreate}
                      checked={
                        organizationCapability?.canCreate
                          ? componentState.selectedVitalsForBulkActions.includes(
                              primaryId
                            )
                          : false
                      }
                    >
                      <VStack space={1} flex={1}>
                        <Text color={Colors.Custom['Gray900']} width="full">
                          {vitalName}
                        </Text>
                        {vitalFormattedData?.value && (
                          <Text color={Colors.Custom['Gray500']}>
                            {vitalFormattedData?.value}{vitalFormattedData.recordedDate ? ` (${vitalFormattedData.recordedDate})` : ''}
                          </Text>
                        )}
                      </VStack>
                    </Checkbox>
                  </View>
                  <Spacer />
                  {organizationCapability?.canCreate && (
                    <HStack space={2}>
                      <CustomButton
                        title="Discard"
                        isDisabled={componentState.showSavingIndicator}
                        onPress={() => {
                          handlePatientReportedActions(
                            PatientRecordActions.deny,
                            item
                          );
                        }}
                      />
                    </HStack>
                  )}
                </HStack>
              </VStack>
            );
          }}
        />
      );
    } else {
      const initialGroupValue: Vital[] = [];
      if (enabledVitals.includes(Vital.bloodPressure)) {
        enabledVitals.splice(0, 1);
        initialGroupValue.push(Vital.bloodPressure);
      }
      const column = 2;
      const displayGroups = enabledVitals.reduce(
        (resultArray, vital, index) => {
          const groupIndex = Math.floor(index / column);
          if (!resultArray[groupIndex]) {
            resultArray[groupIndex] = [];
          }
          resultArray[groupIndex].push(vital);
          return resultArray;
        },
        [] as Array<Vital[]>
      );
      displayGroups.splice(0, 0, initialGroupValue);

      return (
        <VStack flex={1} space={4} paddingY={4}>
          {displayGroups.map((group) => {
            return getVitalInputs(group);
          })}
        </VStack>
      );
    }
  };

  const getEffectiveDateView = () => {
    return (
      <Pressable onPress={(event) => event.stopPropagation()}>
        <View maxWidth={200}>
          <DatePicker
            disabled={props.disabled}
            placeholder={intl.formatMessage({id: 'date'})}
            disabledDate={(current: any) => {
              return current && isFutureDate(current);
            }}
            value={getMomentObj(componentValue.effectiveDateTime || new Date())}
            format={DISPLAY_DATE_FORMAT}
            style={{height: '36px'}}
            className={
              !componentValue.effectiveDateTime &&
              componentValue.observations.length
                ? 'field-error'
                : ''
            }
            onChange={(value) => {
              setComponentValue((prev) => ({
                ...prev,
                effectiveDateTime: value?.toISOString(),
              }));
            }}
          />
        </View>
      </Pressable>
    );
  };

  const getHeaderRightView = (isCompact: boolean, isGraph: boolean) => {
    const extraActions = [
      !isCareProgramEnabled ? {
        tooltip: 'Graph View',
        iconName: 'bar-chart-2',
        onPress: () => {
          setComponentState((prev) => ({...prev, showGraphs: true}));
        },
      } : {},
    ];
    if (!canAddNew()) {
      return (
        <HStack>
          {extraActions?.length &&
            extraActions.map((action) => (
              <Tooltip title={action?.tooltip || ''} placement="bottom" key={action.tooltip} showArrow={false} overlayStyle={{ paddingTop: '3px' }} overlayInnerStyle={tooltipInnerOverlayStyle}>
                <Pressable
                  mr={2}
                  onPress={() => action?.onPress?.()}
                  style={{width: 36, height: 36, justifyContent: 'center', alignItems: 'center'}}
                  rounded="full"
                >
                  <Feather
                    name={action?.iconName || ''}
                    size={20}
                    color={Colors.Custom.foldIconColor}
                  />
                </Pressable>
              </Tooltip>
          ))}
        </HStack>
      );
    };
    if (isCompact || isGraph) {
      return getHeaderButtons(
        () => {
          setComponentState((prev) => ({...prev, editModalVisible: true}));
        },
        'Record Vitals',
        extraActions,
      );
    } else {
      return (
        <HStack space={2} alignItems="center">
          <Spacer />
          {getEffectiveDateView()}
          {contextData.hasLoggedInContext && !isPatientCarePlan && (
            <View>
              <Button
                rounded="3xl"
                backgroundColor={Colors.secondary['100']}
                borderWidth={1}
                borderColor={Colors.secondary['200']}
                isLoading={componentState.showSavingIndicator}
                isLoadingText={'...Saving'}
                _text={{
                  color: Colors.secondary['800'],
                }}
                onPress={() => {
                  addOrUpdateRecord();
                }}
              >
                Record
              </Button>
            </View>
          )}
        </HStack>
      );
    }
  };

  const onAddOrUpdateError = (error: any) => {
    const errorMessage =
      error?.response?.status === HTTP_ERROR_CODE.METHOD_NOT_ALLOWED
        ? intl.formatMessage({
            id: RESOURCE_BLOCK_MESSAGE_ID,
          })
        : error?.response?.data?.message;
    setComponentState((prev) => ({
      ...prev,
      showSavingIndicator: false,
      ...(errorMessage && {customError: errorMessage}),
      ...(!errorMessage && {nonBlockingFormError: FormError.addUpdateFail}),
    }));
  }

  const addOrUpdateRecord = () => {
    if (validateData(componentValue) && componentValue.observations.length) {
      componentValue.observations.forEach((observation) => {
        if (ehrConfig.isAthena) {
          observation.issued = componentValue.effectiveDateTime;
        } else {
          observation.effectiveDateTime = componentValue.effectiveDateTime;
        }
      });
      setComponentState((prev) => ({...prev, showSavingIndicator: true}));
      const fhirData = getFHIRTemplate({
        observations: componentValue.observations,
        patientId: contextData.patientId || '',
        tenantId: contextData.tenantId || '',
      });
      fhirAddOrUpdate(
        FHIR_RESOURCE.OBSERVATION,
        undefined,
        fhirData,
        {
          patientId: contextData.patientId,
          locationId: contextData.locationId,
        },
        (response) => {
          broadcastUpdateEvent();
          getExistingData(contextData.patientId || '');
          setComponentState((prev) => ({
            ...prev,
            showSavingIndicator: false,
            editModalVisible: false,
            nonBlockingFormError: undefined,
          }));
          setComponentValue({
            observations: [],
            effectiveDateTime: getMomentObj(new Date()).toISOString(),
          });
        },
        onAddOrUpdateError
      );
    }
  };

  const handlePatientReportedBulkActions = (action: PatientRecordActions) => {
    const selectedVitals = componentState.patientReported.filter((item) => {
      return item.id && componentState.selectedVitalsForBulkActions.includes(item.id)
    });
    if (selectedVitals.length) {
      setComponentState((prev) => ({...prev, showSavingIndicator: true}));

      const updatedSelectedVitals = selectedVitals.map((selectedVital) => {
        if(ehrConfig.isAthena && selectedVital.effectiveDateTime){
          selectedVital.issued = selectedVital.effectiveDateTime
        }
        return selectedVital;
      });

      const fhirData = getObservationFHIRBundle(updatedSelectedVitals, contextData.patientId || '');
      acceptIgnorePatientReportedRecords(
        FHIR_RESOURCE.OBSERVATION,
        action,
        '-1',
        fhirData,
        {
          patientId: contextData.patientId,
          locationId: contextData.locationId,
        },
        (response) => {
          if (action === PatientRecordActions.accept) {
            broadcastUpdateEvent();
          }
          let keepShowingSavingIndicator = false;
          if (response?.data?.entry || response?.data?.success) {
            if (action === PatientRecordActions.accept) {
              keepShowingSavingIndicator = true;
              getExistingData(contextData.patientId || '', true);
            } else {
              updatePatientReportedDataFromAPIToList(selectedVitals, action);
            }
          }
          setComponentState((prev) => ({
            ...prev,
            ...(!keepShowingSavingIndicator && {showSavingIndicator: false}),
            nonBlockingFormError: undefined,
          }));
        },
        onAddOrUpdateError
      )
    }
  }

  const handlePatientReportedActions = (action: PatientRecordActions, record: IObservation) => {
    setComponentState((prev) => ({...prev, showSavingIndicator: true}));
    const fhirData = getIndividualFHIRTemplate({
      ...record,
      patientId: contextData.patientId || '',
    });
    acceptIgnorePatientReportedRecords(
      FHIR_RESOURCE.OBSERVATION,
      action,
      record.id,
      fhirData,
      {
        patientId: contextData.patientId,
        locationId: contextData.locationId,
      },
      (response) => {
        // NOTE: This is done because when we send event from `Recent Clinical Event` the component view is not same as the current view. And is same when send event from PAMI component as well to handle this, we are sending the view type from the event itself in case of PAMI component so that the api call is made.
        const viewType = isRecentReported ? undefined : FormViewType.compact;
        broadcastUpdateEvent(viewType);
        let keepShowingSavingIndicator = false;
        if (response?.data?.id || response?.data?.success) {
          if (action === PatientRecordActions.accept) {
            keepShowingSavingIndicator = true;
            // Here we only remove the specific Accepted entry so that it doesnot un-match the other matched records.
            setComponentState((prev) => ({
              ...prev,
              patientReported: prev.patientReported.filter(
                (item) => item.id !== record.id
              ),
              showSavingIndicator: false
            }));
          } else {
            updatePatientReportedDataFromAPIToList([record], action);
          }
        }
        setComponentState((prev) => ({
          ...prev,
          ...(!keepShowingSavingIndicator && {showSavingIndicator: false}),
          nonBlockingFormError: undefined,
        }));
      },
      onAddOrUpdateError
    );
  };

  const filterByRelevantVitals = (records: IObservation[]) => {
    const filteredVitals = records?.filter((vital) => {

      if(vital.loinc){
        return props?.options?.relevantVitals?.includes(vital.loinc);
      }
      else if(vital.code?.coding){
        let isFound = false;

        vital.code?.coding?.map((code) => {
          if (
            code?.code &&
            props?.options?.relevantVitals?.includes(code?.code)
          ) {
            isFound = true;
          }
        });

        return isFound;
      }
      return false;
    });
    return filteredVitals;
  }

  const updatePatientReportedDataFromAPIToList = (records: IObservation[], action: PatientRecordActions) => {
    setComponentState((prev) => {
      const list = prev.patientReported;
      const selectedListForBulkAction = componentState.selectedVitalsForBulkActions;
      records.forEach((record) => {
        const index = list.findIndex(
          (value) => value.id === record.id
        );
        if (index > -1) {
          list.splice(index, 1);
        }
        const selectedVitalIndex = selectedListForBulkAction.indexOf(record.id || '');
        if (selectedVitalIndex > -1) {
          selectedListForBulkAction.splice(selectedVitalIndex, 1);
        }
      })

      return {
        ...prev,
        patientReported: list,
        selectedVitalsForBulkActions: selectedListForBulkAction,
        showPatientReportedRecords: list.length > 0
      };
    });
    if (action === PatientRecordActions.accept) {
      getExistingData(contextData.patientId || '');
    }
  };

  const getExistingData = (patientId: string, skipLoader?: boolean, initial?: boolean) => {
    if (!skipLoader) {
      setComponentState((prev) => ({...prev, loading: true}));
    }
    getResourceContentWithHeaders(
      FHIR_RESOURCE.OBSERVATION,
      `patient=${patientId}${
        contextData.hasLoggedInContext ? '&category=vital-signs' : ''
      }${isRecentReported ? `&patientReportedOnly=true` : ''}`,
      !contextData.hasLoggedInContext,
      contextData.headers,
      (response) => {
        handleExistingDataResponse(response, initial || false);
      },
      (error) => {

        setComponentState((prev) => ({
          ...prev,
          loading: false,
          showSavingIndicator: false,
          nonBlockingFormError: FormError.existingDataAPIFail,
        }));
      },
      contextData.locationId,
      !isPatientForm
    );
  };

  const handleExistingDataResponse = (response: any, initial: boolean) => {
    const vitalList = getVitalList();
    if (isPatientForm) {
      const entries: IObservation[] = [];
      for (const el of response?.entry || []) {
        if (el?.resource) {
          const entry = el.resource;
          if (isVitalValueAvailable(entry)) {
            setVitalValuePrecision(entry, vitalList);
            entries.push(entry);
          }
        }
      }
      setComponentState((prev) => ({
        ...prev,
        loading: false,
        showSavingIndicator: false,
        allRecords: entries,
        oldRecords: componentState.seletedFilterTab === CINICAL_SECTIONS_FILTER_TAB_CODES.RELEVANT ? filterByRelevantVitals(entries) : entries,
        activePanels: [componentKeys.VITALS],
        nonBlockingFormError: !response?.entry
          ? FormError.existingDataAPIFail
          : undefined,
      }));
    } else {
      const patientReportedRecords = response?.patientReported?.data?.entry || [];
      const ehrRecords = response?.resource?.data?.entry || [];
      let patientReportedFormattedRecords: IObservation[] = [];
      let ehrFormattedRecords: IObservation[] = [];
      if (patientReportedRecords || ehrRecords) {
        if (patientReportedRecords?.length) {
          patientReportedFormattedRecords = getRecordDataFormatted(
            patientReportedRecords,
            enabledVitalList
          );
        }
        if (ehrRecords?.length) {
          ehrFormattedRecords = getRecordDataFormatted(ehrRecords, vitalList);
        }
      }
      setComponentState((prev) => {
        let patientReportedVitalIds = patientReportedFormattedRecords.map((item) => item.id || '');
        if (!initial) {
          patientReportedVitalIds = prev.selectedVitalsForBulkActions.filter((item) => patientReportedVitalIds.includes(item))
        }

        const formattedVitalData = getFormattedVitalData(sortOldRecords(ehrFormattedRecords), vitalList);

        return {
          ...prev,
          loading: false,
          showSavingIndicator: false,
          ...(!patientReportedFormattedRecords.length && {showPatientReportedRecords: false}),
          allRecords: [...ehrFormattedRecords],
          oldRecords: componentState.seletedFilterTab === CINICAL_SECTIONS_FILTER_TAB_CODES.RELEVANT ? filterByRelevantVitals([...ehrFormattedRecords]) : [...ehrFormattedRecords],
          formattedVitalData: formattedVitalData,
          patientReported: [...patientReportedFormattedRecords],
          selectedVitalsForBulkActions: [...patientReportedVitalIds],
          activePanels: ehrFormattedRecords?.length || patientReportedFormattedRecords?.length ? [componentKeys.VITALS] : [],
          formError: !ehrRecords || !patientReportedRecords
            ? FormError.existingDataAPIFail
            : prev.formError,
        }
      });
    }
  };

  const broadcastUpdateEvent = (viewType?: FormViewType) => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(
      ADD_UPDATE_EVENTS.VITAL,
      viewType || contextData.componentView
    );
  };

  const onEventReceived = useCallback(
    (data) => {
      if (data != contextData.componentView && contextData?.patientId) {
        getExistingData(contextData.patientId, true);
      }
    },
    [contextData]
  );


  useEffect(()=> {
    if (isCompactView()) {
      setComponentState((prev) => ({...prev, loading: true}));
      const range = getDateRange(activityFrequencyUnitCodes.LAST_THREE_MONTHS);
      const patientId = contextData?.patientId || '';
      // NOTE: REMOVED THIS BUT KEEPING CODE COMMENTED FOR FUTURE REFERENCE
        // getActivities(
        //   activityTypeList,
        //   range.startDate.format('YYYY-MM-DD'),
        //   range.endDate.format('YYYY-MM-DD'),
        //   [patientId],
        //   (res: any) => {
        //     const activityData = getPatientData(res?.data || [], patientId);
        //     const formattedActivity = getLastActivityRecord(activityData);
        //     setComponentState((prev) => ({
        //       ...prev,
        //       activityData: formattedActivity,
        //       loading:false,
        //     }));
        //   },
        //   (err: any) => {
        //
        //     setComponentState((prev) => ({
        //       ...prev,
        //       loading: false,
        //     }));
        //   },
        //   false,
        //   contextData.locationId,
        // );
    }
  },[])

  useEffect(() => {
    if (contextData.isBuilderMode || contextData.formError) return;
    const patientId = contextData?.patientId;
    if (patientId) {
      getExistingData(patientId, false, true);
    }
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(ADD_UPDATE_EVENTS.VITAL, onEventReceived);
    eventBus.addEventListener(WINDOW_EVENT_CODES.PATIENT_UPDATES, onEventReceived);
    return () => {
      eventBus.removeEventListener(onEventReceived);
    };
  }, []);

  useEffect(() => {
    const list = componentValue.observations;
    props.onChange(cloneDeep({
      observations: list,
      effectiveDateTime: componentValue.effectiveDateTime,
    }));
  }, [componentValue]);

  useEffect(() => {
    if (isRecentReported) {
      contextData?.updateLoadingStatus?.(componentKeys.VITALS, componentState.loading, !!componentState.patientReported.length);
      return;
    }
    const isDataAvailable = !!componentState.oldRecords.length || !!componentState.patientReported.length;
    contextData?.updateLoadingStatus?.(componentKeys.VITALS, componentState.loading, isDataAvailable);
  }, [componentState.loading]);

  if (props.validateRef) {
    props.validateRef.current = validateData;
  }

  const getPreviewData = (): {
    id: string;
    key: string;
    value: string;
  }[] => {
    const previewData: {
      id: string;
      key: string;
      value: string;
    }[] = [];

    const enabledVitals = getEnabledVitals(
      capabilities,
      props.component.enabledVitals
    );
    const list = getVitalList();

    if (fetchDataForPreview) {
      enabledVitals.map((vital, index) => {
        const display = getDisplay(vital, list);
        const vitalConfig = list.find((item) => {
          return item.loinc === vital;
        });
        const data = getLastRecordText(
          componentState.oldRecords,
          vitalConfig,
          true
        ) as {value: string; recordedDate: string};
        if (data?.value) {
          previewData.push({
            id: uuidv4(),
            key: `${display}`,
            value: `${data?.value}`,
          });
        }
      });
    } else {
      const initialGroupValue: Vital[] = [];

      if (enabledVitals.includes(Vital.bloodPressure)) {
        enabledVitals.splice(0, 1);
        initialGroupValue.push(Vital.bloodPressure);
      }
      const column = 2;
      const displayGroups = enabledVitals.reduce((resultArray, vital, index) => {
        const groupIndex = Math.floor(index / column);
        if (!resultArray[groupIndex]) {
          resultArray[groupIndex] = [];
        }
        resultArray[groupIndex].push(vital);
        return resultArray;
      }, [] as Array<Vital[]>);
      displayGroups.splice(0, 0, initialGroupValue);
      {
        displayGroups.map((group: any) => {
          {
            group.map((vital: any) => {
              const list = getVitalList();
              const selected = componentValue.observations.find((observation) => {
                return observation.loinc === vital;
              });
              const vitalConfig = list.find((item) => {
                return item.loinc === vital;
              });
              if (selected) {
                const vitalFormattedData = getFormattedVitalValue(selected, vitalConfig, true) as {value?: string, recordedDate?: string};
                const display = getDisplay(vital, list);
                if (vitalFormattedData?.value) {
                  previewData.push({
                    id: uuidv4(),
                    key: `${display}`,
                    value: `${vitalFormattedData.value}`,
                  });
                }
              }
            });
          }
        });
      }
    }

    return previewData;
  };
  if (isPreviewMode) {
    const data = getPreviewData();
    if (!data.length) {
      return <></>;
    }
    return (
      <>
        {componentState.loading && (
          <View padding={4}>
            <FormComponentLoader />
          </View>
        )}
        {!componentState.loading && !componentState.formError && (
          <>
            {data.length > 0 && (
              <div className={`${isAllowToShare ? 'page-break' : 'disallow-to-share'}`}>
              <DetailPreview titleLocalId={props.component.label}>
                <FlatList
                  data={data}
                  keyExtractor={(item) => item.id}
                  renderItem={({item}) => (
                      <div style={{display: 'block'}}>
                        <HStack key={item.id} flex={1}>
                          <Text flex={3}>{item.key}</Text>
                          <Text flex={7}>
                            {item.value}
                          </Text>
                        </HStack>
                      </div>
                  )}
                />
              </DetailPreview>
              </div>
            )}
          </>
        )}
      </>
    );
  }

  const getCollapsedView = () => {
    return (
      <View style={styles.collapsedView}>
        <Stack direction="row" style={styles.collapsedHeader}>
          <View flex={1} flexDir={'row'} alignItems={'center'}>
            <PamiIconSvg />
            <Text style={styles.collapsedHeaderText}>
              {intl.formatMessage({id: 'vitals'})}
            </Text>
          </View>
        </Stack>
        <Divider style={styles.collapsedViewDivider} />
        <View>
          {componentState.loading && (
            <View style={styles.loaderContainer}>
              <FormComponentLoader />
            </View>
          )}
          {!componentState.loading && componentState.nonBlockingFormError && (
            <FormComponentError error={componentState.nonBlockingFormError} />
          )}
          {!componentState.loading && componentState.customError && (
              <FormComponentError error={FormError.custom} customError={componentState.customError} />
            )}
          {!componentState.loading && componentState.formError && (
            <FormComponentError error={componentState.formError} />
          )}
          {!componentState.loading && !componentState.formError && (
            getDetailView(false, true, false)
          )}
        </View>
      </View>
    );
  };

  return (
    isSidecarContext ? (
      getCollapsedView()
    ) : (
      <View ref={componentRef}>
        {isCompactView() ? (
          <VStack background={Colors.Custom.BackgroundColor}>
            <Collapse
              activeKey={componentState.activePanels}
              onChange={(activePanels) => {
                setComponentState((prev) => ({...prev, activePanels: activePanels as string[]}));
              }}
              expandIconPosition="end"
              expandIcon={({ isActive }) => {
                const tooltipText = isActive ? intl.formatMessage({id: 'collapse'}) : intl.formatMessage({id:'expand'});
                return (
                  <Tooltip title={tooltipText} placement="bottom" showArrow={false} overlayStyle={{ paddingTop: '12px' }} overlayInnerStyle={tooltipInnerOverlayStyle}>
                    <RightOutlined rotate={isActive ? 90 : 0} />
                  </Tooltip>
                )
              }}
            style={{
              borderRadius: 0,
              borderWidth: 0,
              padding: 0,
              background: 'Colors.Custom.Gray100',
              marginTop: 0,
            }}>
              <Panel forceRender key={componentKeys.VITALS} header={
                <HStack
                  paddingRight={4}
                  justifyContent="space-between"
                >
                  <HStack alignItems={'center'} space={2} marginTop={0}>
                    <PamiIconSvg />
                    <Text
                      color={'#0e0333'}
                      fontSize={20}
                      fontWeight={600}
                      fontFamily={'Manrope-Bold'}
                    >
                      {props.component.label}
                    </Text>
                  </HStack>
                  <View>{getHeaderRightView(isCompactView(), isGraphView())}</View>
                </HStack>}>
                {componentState.loading && (
                  <View padding={4}>
                    <FormComponentLoader />
                  </View>
                )}
                {!componentState.loading && componentState.nonBlockingFormError && (
                  <FormComponentError error={componentState.nonBlockingFormError} />
                )}
                {!componentState.loading && componentState.customError && (
                  <FormComponentError error={FormError.custom} customError={componentState.customError} />
                )}
                {!componentState.loading && componentState.formError && (
                  <FormComponentError error={componentState.formError} />
                )}
                {!componentState.loading &&
                  !componentState.formError &&
                  getDetailView(isCompactView(), isGraphView(), false)}
              </Panel>
            </Collapse>
          </VStack>
        ) : (
          <>
          {isRecentReported ? (
            <RecentReportedViewHeader
              loading={componentState.loading}
              noData={!componentState.patientReported.length}
              title={props.component.label}
              buttonOnPress={() => {
                setComponentState((prev) => ({
                  ...prev,
                  showPatientReportedRecords: true,
                }));
              }}
            />
          ) :
            <Collapse
              activeKey={componentState.activePanels}
              onChange={(activePanels) => {
                setComponentState((prev) => ({...prev, activePanels: activePanels as string[]}));
              }}
              expandIconPosition="end"
              expandIcon={({ isActive }) => {
                const tooltipText = isActive ? intl.formatMessage({id: 'collapse'}) : intl.formatMessage({id:'expand'});
                return (
                  <Tooltip title={tooltipText} placement="bottom" showArrow={false} overlayStyle={{ paddingTop: '12px' }} overlayInnerStyle={tooltipInnerOverlayStyle}>
                    <RightOutlined rotate={isActive ? 90 : 0} />
                  </Tooltip>
                )
              }}
            style={{
              borderRadius: isGraphView() ? 0 : 12,
              borderWidth: isGraphView() ? 0 : 1,
              padding: 0,
              background: 'Colors.Custom.Gray100',
              marginTop: 0,
            }}>
              <Panel forceRender key={componentKeys.VITALS} header={
                <HStack
                  paddingRight={4}
                  justifyContent="space-between"
                >
                  <HStack alignItems={'center'} space={2} marginTop={0}>
                    {isGraphView() && <PamiIconSvg />}
                    <Text
                      color={'#0e0333'}
                      fontSize={20}
                      fontWeight={600}
                      fontFamily={'Manrope-Bold'}
                    >
                      {props.component.label}
                    </Text>
                    {isCareProgramEnabled && (
                      <ClinicalSectionFilterTabs
                        tabs={CINICAL_SECTIONS_FILTER_TABS}
                        selectedTabCode={componentState.seletedFilterTab}
                        onTabClick={(tab: string) => {
                          setComponentState((prev) => ({
                            ...prev,
                            seletedFilterTab: tab,
                          }));
                        }}
                      />
                    )}
                  </HStack>
                  <View>{getHeaderRightView(isCompactView(), isGraphView())}</View>
                </HStack>}>
                <VStack>
                  {componentState.loading && (
                    <View padding={4}>
                      <FormComponentLoader />
                    </View>
                  )}
                  {!componentState.loading && componentState.nonBlockingFormError && (
                    <FormComponentError error={componentState.nonBlockingFormError} />
                  )}
                  {!componentState.loading && componentState.customError && (
                    <FormComponentError error={FormError.custom} customError={componentState.customError} />
                  )}
                  {!componentState.loading && componentState.formError && (
                    <FormComponentError error={componentState.formError} />
                  )}
                  {!componentState.loading &&
                    !componentState.formError &&
                    getDetailView(isCompactView(), isGraphView(), false)}
                </VStack>
                </Panel>
            </Collapse>
          }
          </>
        )}
        <Drawer
          width="35%"
          destroyOnClose
          closable
          open={componentState.showGraphs}
          onClose={() => {
            setComponentState((prev) => ({...prev, showGraphs: false}));
          }}
          bodyStyle={{paddingTop: '0px'}}
          title={
            <ModalActionTitle
              isHeadNotSticky
              title={props.component.label}
              buttonList={[
                {
                  onClick: () => {
                    setComponentState((prev) => ({
                      ...prev,
                      showGraphs: false,
                    }));
                  },
                  show: true,
                  id: 1,
                  btnText: intl.formatMessage({
                    id: 'close',
                  }),
                  size: 'sm',
                  textColor: Colors.Custom.mainSecondaryBrown,
                  variant: BUTTON_TYPE.SECONDARY,
                },
              ]}
            />
          }
        >
          {getDetailView(false, true, false)}
        </Drawer>
        <Drawer
          title={
            <ModalActionTitle
              isHeadNotSticky
              title={'Vitals'}
              buttonList={[
                {
                  onClick: () => {
                    setComponentState((prev) => ({
                      ...prev,
                      editModalVisible: false,
                    }));
                  },
                  show: true,
                  id: 1,
                  btnText: intl.formatMessage({
                    id: 'close',
                  }),
                  size: 'sm',
                  textColor: Colors.Custom.mainSecondaryBrown,
                  variant: BUTTON_TYPE.SECONDARY,
                },
              ]}
            />
          }
          destroyOnClose
          placement="right"
          onClose={() => {
            setComponentState((prev) => ({...prev, editModalVisible: false}));
          }}
          open={componentState.editModalVisible}
          closable
          width="50%"
        >
          <DetailViewCollapse
            btnList={[]}
            textLocalId={props.component.label}
            toggled={!componentState.editModalCollapse}
            headerRightView={getHeaderRightView(false, false)}
            onToggled={() => {
              setComponentState((prev) => ({
                ...prev,
                editModalCollapse: !prev.editModalCollapse,
              }));
            }}
          >
            <VStack>
              {componentState.loading && <FormComponentLoader />}
              {!componentState.loading && componentState.nonBlockingFormError && (
                <FormComponentError error={componentState.nonBlockingFormError} />
              )}
              {!componentState.loading && componentState.customError && (
                <FormComponentError error={FormError.custom} customError={componentState.customError} />
              )}
              {!componentState.loading && componentState.formError && (
                <FormComponentError error={componentState.formError} />
              )}
              {!componentState.loading &&
                !componentState.formError &&
                getDetailView(false, false, false)}
            </VStack>
          </DetailViewCollapse>
        </Drawer>
        <Drawer
          title={
            <ModalActionTitle
              isHeadNotSticky
              title={'Patient Reported Vitals'}
              buttonList={[
                {
                  isDisabled: componentState.showSavingIndicator,
                  isLoading: componentState.showSavingIndicator,
                  onClick: () => {
                    setComponentState((prev) => ({
                      ...prev,
                      showPatientReportedRecords: false,
                      selectedVitalsForBulkActions:
                        componentState.patientReported.map(
                          (item) => item.id || ''
                        ),
                    }));
                  },
                  show: true,
                  id: 1,
                  btnText: intl.formatMessage({
                    id: componentState.showSavingIndicator ? 'Saving' : 'close',
                  }),
                  size: 'sm',
                  textColor: componentState.showSavingIndicator
                    ? Colors.primary[800]
                    : Colors.danger[800],
                  variant: componentState.showSavingIndicator
                    ? BUTTON_TYPE.TERTIARY
                    : BUTTON_TYPE.SECONDARY,
                },
              ]}
            />
          }
          destroyOnClose
          placement="right"
          onClose={() => {
            setComponentState((prev) => ({
              ...prev,
              showPatientReportedRecords: false,
              selectedVitalsForBulkActions: componentState.patientReported.map(
                (item) => item.id || ''
              ),
            }));
          }}
          open={componentState.showPatientReportedRecords}
          closable
          width="50%"
        >
          <VStack>
            {componentState.loading && <FormComponentLoader />}
            {!componentState.loading && componentState.nonBlockingFormError && (
              <FormComponentError error={componentState.nonBlockingFormError} />
            )}
            {!componentState.loading && componentState.customError && (
              <FormComponentError error={FormError.custom} customError={componentState.customError} />
            )}
            {!componentState.loading && componentState.formError && (
              <FormComponentError error={componentState.formError} />
            )}
            {!componentState.loading &&
              !componentState.formError &&
              getDetailView(false, false, true)}
          </VStack>
        </Drawer>
      </View>
    )
  );
};

const styles = StyleSheet.create({
  sidecarGraphView: {
    padding: 5,
    paddingTop: 3,
  },
  defaultGraphView: {
    paddingX: 4,
    paddingBottom: 4,
    marginTop: 2,
  },
  collapsedView: {
    borderRadius: 8,
    backgroundColor: Colors.Custom.White,
    overflow: 'hidden',
  },
  collapsedHeader: {
    alignItems: 'center',
    marginVertical: 16,
    marginHorizontal: 18,
  },
  collapsedHeaderText: {
    color: '#0e0333',
    fontSize: 20,
    fontWeight: '600',
    fontFamily: 'Manrope-Bold',
    marginLeft: 3,
  },
  collapsedViewDivider: {
    margin: 0,
  },
  loaderContainer: {
    padding: 16,
  },
});

export default AddOrUpdateVitals;
