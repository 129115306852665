import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Drawer, Modal, Popover, Segmented, Select, Tooltip, Upload, UploadFile, notification } from 'antd';
import { cloneDeep, debounce } from 'lodash';
import {
  Divider,
  FormControl,
  HStack,
  InfoOutlineIcon,
  Pressable,
  Skeleton,
  Stack,
  Text,
  VStack,
  View,
  useMediaQuery,
} from 'native-base';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Dimensions } from 'react-native';
import Icon from 'react-native-vector-icons/AntDesign';
import { v4 as uuidv4 } from 'uuid';
import { IUser } from '../../../../Interfaces';
import {
  BUTTON_TYPE,
  CUSTOM_AUTOMATION_URL,
  DATE_FORMATS,
  DISPLAY_DATE_FORMAT,
  ENTITY_EVENTS_TITLE_SUBTITLE,
  ENTITY_EVENTS_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
  PERSON_TYPES,
  RIGHT_SIDE_CONTAINER_CODE,
  SMALL_WINDOW_1700,
  TASK_ATTACHMENT_SOURCE,
  TASK_POOL_LOCAL_TYPE,
  getInActiveContactError,
  IS_SAVE_CLICK
} from '../../../../constants';
import {
  COMMON_ACTION_CODES,
  CONVERSATION_ACTION_CODES,
} from '../../../../constants/ActionConst';
import { LABEL_TYPE_CODES, MLOV_CATEGORY, PATIENT_CARE_JOURNEY_STATUS_CODE, TASK_SUBJECT_TYPE, USER_ROLE_CODES } from '../../../../constants/MlovConst';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { GetLabelsByLabelTitleAndTypeId } from '../../../../services/Labels/LabelsQueries';
import { CreateLabel, CreateLabelType } from '../../../../services/Tags/TagQueries';
import { Colors } from '../../../../styles';
import {
  getDateStrFromFormat,
  getMomentObj,
  getMomentObjectWithDateStringAndFormat,
  isCurrentDateInFutureComparedToOther,
} from '../../../../utils/DateUtils';
import {
  getAccountId,
  getBooleanFeatureFlag,
  getCaslAbility,
  getUserData,
  getUserEmployerId,
  getUserUUID,
  isActiveContact,
  isEmployerRole,
} from '../../../../utils/commonUtils';
import { getContactTypeId, getMlovCodeFromId, getMlovId, getMlovListFromCategory, getMlovValue } from '../../../../utils/mlovUtils';
import { FoldButton } from '../../../CommonComponents/FoldButton/FoldButton';
import { UPLOAD_ACCEPTED_FILE_TYPES } from '../../../PersonOmniView/LeftContainer/OtherDetails/PatientDocuments/UploadPatientDocument';
import { IAccountData } from '../../../RightSideContainer/Contacts/TeamMembers/EditAccount/interface';
import MessagingContactDetailsDrawer from '../../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/MessagingContactDetailsDrawer';
import {
  USER_ACCESS_PERMISSION
} from '../../../RightSideContainer/UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../../SideMenuBar/SideBarConst';
import { BottomViewAction, isTaskWithType } from '../../../TaskCard/TaskCardHelper';
import WorkFlowListByEntityEvents from '../../CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/WorkFlowListByEntityEvents';
import { ParticipantType } from '../../CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import UserAutoComplete, {
  IUserSearch,
} from '../../CalendarWidget/UserAutoComplete/UserAutoComplete';
import { ILabelTask, IStateForHandlingPoolAfterTaskAssigneeChange, IStateForHandlingSubTaskAssigneeAfterPoolChange, ITask } from '../../CareDashboard/CareDashboardInterfaces';
import { TASK_ASSIGNEE_TYPE_CODES } from '../../CareDashboard/CareDashboardTable/CareDashboardTableHelper';
import { TASK_ACTIONS, TASK_CATEGORY } from '../../CareDashboard/CareDashboardUtils/CareDashboardUtils';
import { IUserPatientSearchItem } from '../../CustomComponents/CustomUserPatientSearch/CustomUserPatientSearch.native';
import { DisplayText } from '../../DisplayText/DisplayText';
import { ModalActionAntSelect } from '../../ModalActionCommonComponent/ModalActionAntSelect';
import { ModalActionDatePicker } from '../../ModalActionCommonComponent/ModalActionDatePicker';
import { ModalActionInput } from '../../ModalActionCommonComponent/ModalActionInput';
import { ModalActionTitle } from '../../ModalActionTitle/ModalActionTitle';
import PatientSearchAndSelect from '../../PatientSearch/PatientSearchAndSelect';
import { RichTextEditor, TEXT_ONLY_MODULES } from '../../RichTextEditor/RichTextEditor';
import ArrowDownSvg from '../../Svg/ArrowDownSvg';
import ArrowRightSvg from '../../Svg/ArrowRightSvg';
import PlusIcon from '../../Svg/PlusSvg';
import AddOrUpdateCommentForTask from '../AddEditComment/AddOrUpdateCommentForTask';
import { ADD_COMMENT_CONST, ADD_SUBTASK_CONST, manageAttachmentsListData, uniqueArray } from '../AddTaskUtils';
import { getMessage, hasMatchingLocation } from '../CommonUtilsForWebAndNative';
import TaskDetailsPreview from '../TaskDetailsPreview';
import TaskPoolSearchAndSelect from '../TaskPoolSearchAndSelect';
import { IAddTaskViewProps, IPoolInfo, ISubTasks, ITaskCommentsList, ITaskPool, ITaskViewComponentState } from '../interfaces';
import AddSubTasks from './AddSubTasks';
import AttachmentsItemList from './AttachmentsItemList';
import { DEFAULT_TASK_LABEL_COLOR } from './TaskConstant';
import {TaskDrawerAction} from './TaskDrawerAction';
import {EntityType} from '../../../TaskCard/TaskEnum';
const {Option} = Select;
import { withMiniContactViewHOC } from '../../../MiniContactViewHOC';
import { TaskViewTabsCode, TASK_VIEW_TABS } from '../AddEditComment/TaskCommentsHelper';
import { testID, TestIdentifiers } from '../../../../testUtils';
import { MediaSelectionModal } from '../../../RightSideContainer/ContentManagement/PatientEducation/MediaSelectionModal';
import { MEDIA_VIEW_CODES, mediaFilter } from '../../../RightSideContainer/ContentManagement/MediaLibrary/MediaLibraryUtils';
import MediaSelectFolder from '../../Svg/MediaSelectFolder';
import UploadMediaSvg from '../../Svg/UploadMediaSvg';
import Feather from 'react-native-vector-icons/Feather';
import { IMediaLibraryData } from '../../../RightSideContainer/ContentManagement/MediaLibrary/interfaces';
import { PatientLinkNoteCard } from '../../../PersonOmniView/MiddleContainer/CareTimeline/PatientLinkNoteCard';
import { useNavigate } from 'react-router-dom';
import {createLabelAndLabelType} from '../../../RightSideContainer/Sales/ProductsAndServices/JourneyPackages/PackageServices';
import { redirectToNewTab } from '../../../SideCar/SidecarUtils';
import PatientLinkCarePlanCard from './components/CarePlanLinkCard';
import { TaskExtraDetailsView } from './TaskExtraDetailsView';
import WorkFlowListForTasks from '../../../TaskCard/WorkFlowListForTasks';
import { TaskDrawerActionWithSelect } from './TaskDrawerActionWithSelect';
import '../../ModalActionCommonComponent/ModalActionStyle.css';
import CarePlan from '../../../PersonOmniView/MiddleContainer/CarePlan/CarePlan';
import {ICarePlanProps} from '../../../PersonOmniView/MiddleContainer/CarePlan/interfaces';
import {LeftOutlined} from '@ant-design/icons';
import '../../../common/CalendarWidget/BookingWorkflows/ScheduleSuggestor/components/ScheduleRuleMemberList/styles.scss';
import FoldPermitCan from '../../../CommonComponents/FoldPermitCan/FoldPermitCan';
import FeatureFlags from '../../../../constants/FeatureFlags.enums';

const AddOrUpdateTaskView = (props: IAddTaskViewProps) => {
  //destruct props
  const {
    loading,
    value,
    task,
    taskPriorityMlov,
    taskConfig,
    isVisible,
    defaultAssignee,
    handleChange,
    onCancel,
    onAddTask,
    onMarkAsComplete,
    accountUsers,
    taskPools,
    taskLabels,
    handleSubTaskChange,
    subTasks,
    subTaskLoading,
    fileList,
    comments,
    handleCommentChange,
    fetchAllTypeTask,
    labelsLoading,
    linkPatientNote,
    onNoteRedirect,
    isFormValid,
    carePlanProps,
    showLinkedCarePlan,
    onChangeCarePlanVisible,
    allowedAccountLocations,
  } = props;
  const navigate = useNavigate();
  const targetRef: any = useRef();
  const editTask: ITask | undefined = task;
  const isEditTask = !!editTask?.id;
  const subTaskRef: any = useRef();
  const fileListWithoutDeleted = fileList?.filter(item => !item.isDeleted)
  const currentUserId = getUserUUID();
  const employerId = getUserEmployerId();

  const mlovData = useContext(CommonDataContext);
  const intl = useIntl();
  const isMultiTenancyEnabled = getBooleanFeatureFlag(mlovData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const leadContactTypeId = getContactTypeId('LEAD');
  const contactTypeId = getContactTypeId('CUSTOMER');
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    { maxWidth: IPAD_WIDTH },
    { maxWidth: IPAD_MINI_WIDTH },
  ]);
  const screenDimensions = Dimensions.get('window');
  const smallWindow= screenDimensions?.width < SMALL_WINDOW_1700
  const isEmployer = isEmployerRole();
  const isSelectedAssigneeAndDefaultAssigneeSame = value.assignee?.value === defaultAssignee?.value
  const currentUserData = getUserData();
  const currentUser = {
    value: currentUserId,
    label: currentUserData.name,
    key: currentUserId,
    type: ParticipantType.staff,
    details: currentUserData,
  }
  const [taskPoolData, setTaskPoolData] = useState<ITaskPool>({
    assigneeChangedDueToChangeInTaskPool: false,
  });
  const [selectedViewCode, setSelectedViewCode] = useState<{
    code: string;
    task?: ITask;
  }>({
    code: '',
  });

  const isSidecarContext = !!mlovData?.sidecarContext?.isSidecar;
  const [selectedRowContactData, setSelectedRowContactData]: any = useState({});
  const [expandSubtask, setExpandSubtask] = useState(true);
  const [stateForHandlingSubTaskAssigneeAfterPoolChange, setStateForHandlingSubTaskAssigneeAfterPoolChange] = useState<IStateForHandlingSubTaskAssigneeAfterPoolChange>({
    showAlertOfSubTaskAssigneeChange: false,
    temporaryUserPoolSelection: {assigneeChangedDueToChangeInTaskPool: false}
  })
  const [stateForHandlingPoolAfterTaskAssigneeChange, setStateForHandlingPoolAfterTaskAssigneeChange] = useState<IStateForHandlingPoolAfterTaskAssigneeChange>({
    showAlertOfEmptyingTaskPool: false,
    closeSelect: false,
    temporaryData: {}
  })
  const defaultTab = TASK_VIEW_TABS?.find((tab) => tab?.code === TaskViewTabsCode?.ACTIVITY)
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const tabOptions = TASK_VIEW_TABS.map((tab) => ({
    label: tab.title,
    value: tab.code,
  }));
  const [componentState, setComponentState] = useState<ITaskViewComponentState>({
    showMediaDrawer: false,
    showAttachmentsPopover: false,
    selectedMedia: {} as IMediaLibraryData
  })
  const mlovs = getMlovListFromCategory(mlovData.CARE_STUDIO_MLOV, MLOV_CATEGORY.PATIENT_CARE_JOURNEY_STATUS) || [];


  const [taskLabelData, setTaskLabelData] = useState<{
    taskLabelsList?: ILabelTask[];
    limit: number;
    offset: number;
    loading: boolean;
    searchString?: string;
    selectedLabels?: string[];
  }>({
    taskLabelsList: [],
    limit: 10,
    offset: 0,
    searchString: '',
    loading: false,
    selectedLabels: [],
  });

  const labels = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.LABEL_TYPE
  );
  const filteredResult = labels?.filter((item) => {
    return item.code === LABEL_TYPE_CODES.TASK;
  });
  const mlov = filteredResult[0];
  const accountId = getAccountId();
  const selectedAccountLocationCheck =  !isMultiTenancyEnabled  || value?.selectedAccountLocations?.length;
  const scrollToComment = () => {
    if (props?.showCommentError && !isSidecarContext) {
      targetRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }
  const scrollToElement = () => {
    if (task?.scrollTo === 'subtask' && subTaskRef?.current && !isSidecarContext) {
      subTaskRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
    else if (targetRef?.current && !isSidecarContext) {
      targetRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };
  //FUNCTIONS
  const checkIfUserIsEmployer = (user: IUser) => {
    if (user.userRoles && user.userRoles.length > 0) {
      const isEmployer = user.userRoles.find(
        (role) => role.userRole?.userRole?.code === USER_ROLE_CODES.EMPLOYER
      );
      return isEmployer ? true : false;
    }
    return false;
  };
  const userDataPostProcessing = (users: IUser[]) => {
    let filteredUserData: IUser[] = [];
    if (users && users.length > 0) {
      if (isEmployer) {
        filteredUserData = users.filter((user: IUser) => {
          if (user.uuid === currentUserId) {
            // if user is employer then show only his own name
            return true;
          }
          return !checkIfUserIsEmployer(user); // add only non-employer users
        });
      }
    }
    return filteredUserData;
  };

  const getSearchtaskLabel = useCallback( debounce((value) => {
    onSearchTaskLabel(value);
  }, 500), []);

  const isAssigneeTypePatientOrProspect = () => {
    const isMember = (
      value.assignee?.type === ParticipantType.patient ||
      value.assignee?.type === ParticipantType.leads ||
      value.assignee?.type === TASK_ASSIGNEE_TYPE_CODES.Contact
    );
    return isMember
  };

  const getMatchActiveUserById = (userId: string, accountUserList?: any[]): any => {
    return (accountUserList || accountUsers || []).find(user => {
      return user?.userId === userId;
    });
  }

  const getUserObjectForDisplay = (selectedUser: any) => {
    return {
      key: selectedUser?.userId,
      value: selectedUser?.userId,
      label: selectedUser?.name,
      contactData: selectedUser,
      type: ParticipantType?.staff
    }
  }

  const showStatusAction = ()=> {
    if (taskConfig?.isEditTask) {
      return true
    }
    return false
  }

  const filterActiveUsersAndDefaultAssigneeOfPool = (pool: any) => {
    const selectedPoolUsers: any[] = [];
    let defaultAssignee: any = undefined;
    if (pool?.id && pool?.userPoolUsers?.length) {
      const selectedAccountLocations = value?.selectedAccountLocations || [];
      (pool?.userPoolUsers || []).forEach((user: any) => {
        const userPracticeLocations = user?.user?.userPracticeLocations || [];
        const selectedUser = getMatchActiveUserById(user?.userId);
        if (selectedUser?.userId && (!isMultiTenancyEnabled || hasMatchingLocation(userPracticeLocations, selectedAccountLocations))) {
          const userDisplayObject = getUserObjectForDisplay(selectedUser);
          if (user.isDefault) {
            defaultAssignee = userDisplayObject;
          } else {
            selectedPoolUsers.push(userDisplayObject);
          }
        }
      });
    }
    return {
      defaultAssignee,
      selectedPoolUsers,
    };
  }

  const getPoolListByUserId = (userId: string) => {
    const userPoolList: any[] = [];
    const otherUserPoolList: any[] = [];
    (taskPools || [])?.forEach((pool: any) => {
      const isSelectedUserInPool = pool?.userPoolUsers?.some((user: any) => user?.userId === userId);
      const poolClone = {
        ...pool,
        type: isSelectedUserInPool ? TASK_POOL_LOCAL_TYPE.SELECTED_USER : TASK_POOL_LOCAL_TYPE.OTHER_USER,
      };
      if (isSelectedUserInPool) {
        userPoolList.push(poolClone);
      } else {
        otherUserPoolList.push(poolClone);
      }
    });
    return {
      userPool: userPoolList,
      otherPool: otherUserPoolList,
    };
  };

  const getMatchTaskPoolDetail = (userPoolId: string | undefined, userId: string) => {
    const selectedPool = (taskPools || [])?.find((pool: any) => {
      return pool?.id === userPoolId;
    });
    if (!selectedPool) {
      return undefined;
    }
    const isSelectedUserInPool = selectedPool?.userPoolUsers?.some((user: any) => user?.userId === userId);
    return {
      key: selectedPool.id,
      value: selectedPool.id,
      label: selectedPool.name,
      userPoolUsers: selectedPool.userPoolUsers,
      type: isSelectedUserInPool ? TASK_POOL_LOCAL_TYPE.SELECTED_USER : TASK_POOL_LOCAL_TYPE.OTHER_USER,
    };
  };

  const configureTaskPool = () => {
    let filterUsers: any[] = [];
    if (!taskPoolData?.accountUsers?.length && isEmployer) {
      filterUsers = userDataPostProcessing(cloneDeep(accountUsers || []));
    }
    if (taskPools?.length && (!taskPoolData?.otherUserPools?.length || !taskPoolData?.currentUserPools?.length)) {
      const { userPool, otherPool } = getPoolListByUserId(currentUserId);
      let selectedPool: any = {};
      if (value?.taskPool?.value || (props?.task?.id && props?.task?.userPoolId)) {
        const poolId = value?.taskPool?.value || props?.task?.userPoolId;
        selectedPool = getMatchTaskPoolDetail(poolId, currentUserId) || {};
        selectedPool.id = selectedPool?.key;
        // if login user present in selected task pool and add or update modal open from task pool list
        if (selectedPool?.type === TASK_POOL_LOCAL_TYPE.SELECTED_USER && !value?.assignee) {
          const selectedUser = getMatchActiveUserById(currentUserId, accountUsers);
          handleChange({
            ...value,
            assignee: {
              key: selectedUser?.uuid,
              value: selectedUser?.uuid,
              label: selectedUser?.name,
              type: ParticipantType.staff,
              details: selectedUser,
            },
          });
        }
      }
      const { selectedPoolUsers, defaultAssignee } = filterActiveUsersAndDefaultAssigneeOfPool(selectedPool);
      setTaskPoolData(prev => {
        return {
          ...prev,
          otherUserPools: otherPool,
          currentUserPools: userPool,
          accountUsers: filterUsers,
          ...(selectedPool?.id && { selectedPool }),
          ...(selectedPool?.id && { selectedPoolUsers }),
          ...(selectedPool?.id && { defaultAssignee }),
        }
      });
    }
  }

  const handleChangeInTaskPool = (data: any, isAssigneeInPool?: boolean) => {
    if (data?.key !== taskPoolData?.selectedPool?.id) {
      if (data?.key) {
        const taskPool = { key: data?.key, value: data?.key, label: data?.label }
        data.id = data?.key;
        const { selectedPoolUsers, defaultAssignee } = filterActiveUsersAndDefaultAssigneeOfPool(data);
        const assignee = defaultAssignee ? defaultAssignee : data?.type === TASK_POOL_LOCAL_TYPE.SELECTED_USER ? currentUser : undefined;
        const allAssigneeOfSubTasksInTaskPool = (subTasks && subTasks?.length > 0 && subTasks?.some((item: ISubTasks) => item?.assignee?.key)) ? isSubTaskAssigneeInPool(data, subTasks) : undefined;
        if (subTasks && subTasks?.length > 0 && (!(subTasks?.some((item: ISubTasks) => item?.assignee?.key)) || !allAssigneeOfSubTasksInTaskPool)) {
          // if all sub tasks have empty assignee field, assign them all default asignee of pool or undefined
          if (!(subTasks?.some((item: ISubTasks) => item?.assignee?.key))) {
            handleChange({ ...value, ...(!isAssigneeInPool && { assignee }), taskPool });
            setTaskPoolData(prev => {
              return {
                ...prev,
                selectedPoolUsers,
                defaultAssignee,
                assigneeChangedDueToChangeInTaskPool: assignee && !isAssigneeInPool ? true : false,
                selectedPool: { name: data?.label || data?.value, ...data }
              }
            });
            subTasks?.forEach((subTaskItem) => {
              const updateAssignee = {
                ...subTaskItem,
                assignee: {
                  key: assignee?.key,
                  value: assignee?.value,
                  label: assignee?.label,
                  type: assignee?.type,
                  details: assignee,
                },
              };
              handleSubTaskChange?.(
                ADD_SUBTASK_CONST.CHANGE_ASSIGNEE,
                updateAssignee
              );
              // }
            })
          }
          //if any of the sub task has an assignee and it is not in the selected task pool, then show alert.
          else if (!allAssigneeOfSubTasksInTaskPool) {
            setStateForHandlingSubTaskAssigneeAfterPoolChange((prev: any) => {
              return {
                ...prev,
                showAlertOfSubTaskAssigneeChange: true,
                temporaryUserPoolSelection: {
                  selectedPoolUsers: selectedPoolUsers,
                  defaultAssignee: defaultAssignee,
                  assigneeChangedDueToChangeInTaskPool: assignee && !isAssigneeInPool ? true : false,
                  selectedPool: { name: data?.label || data?.value, ...data },
                  isAssigneeInPool: isAssigneeInPool,
                  assignee: assignee,
                  taskPool: taskPool,
                  isSubtaskAssigneeChange: true,
                  ...(!defaultAssignee && taskPoolData?.currentUserPools?.map((pool: IPoolInfo) => pool?.id)?.includes(data?.key) && { subtaskAssigneeChangeToCurrentUser: true }),
                  ...(!isAssigneeInPool && !defaultAssignee && taskPoolData?.currentUserPools?.map((pool: IPoolInfo) => pool?.id)?.includes(data?.key) && { taskAssigneeChangeToCurrentUser: true })
                }
              }
            })
          }
        }
        else {
          if (value?.assignee?.key && !isAssigneeInPool) {
            setStateForHandlingSubTaskAssigneeAfterPoolChange((prev: any) => {
              return {
                ...prev,
                showAlertOfSubTaskAssigneeChange: true,
                temporaryUserPoolSelection: {
                  selectedPoolUsers: selectedPoolUsers,
                  defaultAssignee: defaultAssignee,
                  assigneeChangedDueToChangeInTaskPool: assignee && !isAssigneeInPool ? true : false,
                  selectedPool: { name: data?.label || data?.value, ...data },
                  isAssigneeInPool: isAssigneeInPool,
                  assignee: assignee,
                  taskPool: taskPool,
                  ...((!defaultAssignee && taskPoolData?.currentUserPools?.map((pool: IPoolInfo) => pool?.id)?.includes(data?.key)) && { taskAssigneeChangeToCurrentUser: true })
                }
              }
            })
          }
          else {
            setTaskPoolData(prev => {
              return {
                ...prev,
                selectedPoolUsers,
                defaultAssignee,
                assigneeChangedDueToChangeInTaskPool: assignee && !isAssigneeInPool ? true : false,
                selectedPool: { name: data?.label || data?.value, ...data },
              }
            });
            handleChange({ ...value, ...(!value?.assignee?.key && { assignee }), taskPool });
          }
        }
      } else {
        setTaskPoolData(prev => {
          return {
            ...prev,
            selectedPool: undefined,
            selectedPoolUsers: [],
            defaultAssignee: undefined,
          }
        });
        handleChange({ ...value, taskPool: undefined });
      }
    }
  }

  const handleAssigneeChange = (assignee: IUserPatientSearchItem, type?: string) => {
    if (isEmployer) {
      handleChange({
        ...value,
        assignee: assignee as IUserPatientSearchItem,
      });
    }
    else {
      setTaskPoolData(prev => {
        return {
          ...prev,
          assigneeChangedDueToChangeInTaskPool: false
        }
      });
      if (type !== ParticipantType.staff) {
        handleChange({
          ...value,
          taskDisplayCategoryId: '',
        });
        setTaskPoolData(prev => {
          return {
            ...prev,
            selectedPool: undefined,
            selectedPoolUsers: [],
            defaultAssignee: undefined,
          }
        });
      }
      handleChange({
        ...value,
        assignee: assignee,
        ...(type === ParticipantType.staff && !(taskPoolData?.selectedPool?.userPoolUsers?.some((user: any) => { if (user.userId === assignee?.key) return true }))) && {
          taskPool: undefined
        },
        ...(type && type !== ParticipantType.staff && {
          customer: undefined,
          selectedContact: undefined,
          taskPool: undefined,
        }),
      });
    }
  }

  const getDefaultDueDate = () => {
    const dueDateMomentObject = getMomentObjectWithDateStringAndFormat(
      value.dueDate,
      DISPLAY_DATE_FORMAT
    )
    const currentDate = getMomentObj(new Date())
    return (
      dueDateMomentObject.isValid() ? dueDateMomentObject  :  currentDate
    )
  };

  useEffect(() => {
    configureTaskPool();
    if (task?.isAutoScroll) {
      setTimeout(() => {
        scrollToElement();
      }, 1000);
    }
  }, [taskPools, accountUsers]);

  useEffect(() => {
    if(props?.showCommentError){
      const timeoutId = setTimeout(() => {
        scrollToComment();
      }, 1000);
      props?.handleCommentErrorAction && props?.handleCommentErrorAction(IS_SAVE_CLICK,false);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [props?.isNeedToScrollDown]);
  useEffect(() => {
   const selectedLabels = taskLabels?.map(item => item.uuid) || [] as any[];
   if (selectedLabels?.length) {
    setTaskLabelData((prev: any) => {
      return {
        ...prev,
        selectedLabels: selectedLabels || [] as string[],
        taskLabelsList: taskLabels,
        isLoading: false,
      }
    })
   }
  }, [taskLabels])

  const renderTaskStatus = () => (
    <TaskDrawerActionWithSelect
      actions ={TASK_ACTIONS}
      task={task || {} as ITask}
      onComplete={()=>{
        fetchAllTypeTask?.();
        onCancel?.();
      }}
      linkPatientNote={linkPatientNote}
      linkedCarePlanId={props.linkedCarePlanId}
      disableTaskStatusChange={!isEditTask}
    />
  )
  const renderAccountLocations = () => (
    <>
      <ModalActionAntSelect
        className={'custom-task-style'}
        leftMargin={'0'}
        showSearch={false}
        labelInValue={true}
        filterOption={false}
        value={value?.selectedAccountLocations?.[0]?.practiceLocation?.name}
        isRequired={true}
        isInvalid={value.showErrors}
        label={'Location'}
        placeholder={'Location'}
        onChange={(data: any) => {
          const selectedLocations = allowedAccountLocations?.filter((location: any) => location?.uuid === data?.key);
          handleChange({
            ...value,
            ...((value?.selectedAccountLocations && value?.selectedAccountLocations?.length > 0) && {
              assignee: undefined,
              customer: undefined,
              taskPools: taskPools?.filter((taskPool: any) => {
                return taskPool?.userPoolLocations?.find((location: any) => location?.locationId === selectedLocations?.[0]?.uuid)
                }), 
            }),
            selectedAccountLocations: selectedLocations,
          });
        }}
        data={allowedAccountLocations}
        optionProps={{
          key: 'uuid',
          value: 'name',
          label: 'name',
        }}
        extraStyle={{ flex: 1, fontColor: Colors.Custom.alertsDescriptionColor}}
        customStyle={{backgroundColor: Colors.Custom.White, borderRadius: 4, borderColor: Colors.Custom.alertsDescriptionColor, height: 40}}
        selectStyle={{fontColor: Colors.FoldPixel.GRAY400}}
        isSidecarTaskLabelSelect={isSidecarContext}
      />
    </>
  );

  const renderTitle = () => (
    <ModalActionInput
      maxLength={300}
      fieldIs={'input'}
      label={'Title'}
      leftMargin={'0'}
      isRequired={true}
      isInvalid={value.showErrors && value.title.trim().length === 0}
      value={value.title}
      onChangeText={(text:any) => {
        handleChange({ ...value, title: text });
      }}
      extraStyle={{ flex: 1, fontColor: Colors.Custom.alertsDescriptionColor}}
      borderRadius={4}
      borderColor={Colors.FoldPixel.GRAY150}
    />
  );
  const renderDescription = () => (
    <>
      <View>
        <FormControl isRequired={false}>
          <HStack alignItems={'center'} flex={1}>
            <FormControl.Label marginLeft={0} flex={1}>
              <DisplayText
                size={'smMedium'}
                extraStyles={{
                  color: Colors.Custom.alertsDescriptionColor,
                  fontWeight: 500,
                  fontSize: 14,
                }}
                textLocalId={'Description'}
              />
            </FormControl.Label>
          </HStack>
          <VStack>
            <View flexDirection={'row'}>
              <View flex={1}>
                <RichTextEditor
                  style={{borderRadius: fileListWithoutDeleted?.length ? 0 : 4, borderColor: Colors.FoldPixel.GRAY150}}
                  modules={TEXT_ONLY_MODULES}
                  valueStr={value.description}
                  hideBottomBorder={
                    fileListWithoutDeleted?.length ? true : false
                  }
                  onChangesValue={(data: any) => {
                    handleChange({
                      ...value,
                      description: data,
                    });
                  }}
                />
                <Popover
                overlayInnerStyle={{
                  // bottom: 3,
                  borderRadius: 12,
                  padding: 0,
                  width: 200,
                }}
                trigger="click"
                placement="bottomLeft"
                open={componentState.showAttachmentsPopover}
                onOpenChange={(newOpen: boolean) => {
                  if (!newOpen) {
                    setComponentState((prev) => {
                      return {
                        ...prev,
                        showAttachmentsPopover: newOpen,
                      };
                    });
                  }
                }}
                showArrow={false}
                overlayStyle={{borderRadius: 4}}
                content={
                  <VStack space={3}>
                    <Pressable
                      onPress={() =>
                        setComponentState((prev) => {
                          return {
                            ...prev,
                            showMediaDrawer: true,
                            showAttachmentsPopover: false,
                          };
                        })
                      }
                    >
                      <HStack space={3} alignItems={'center'}>
                        <MediaSelectFolder />
                        <Text fontWeight={400}>
                          {intl.formatMessage({id: 'selectFromMedia'})}
                        </Text>
                      </HStack>
                    </Pressable>
                    <Upload
                      multiple={true}
                      beforeUpload={() => false}
                      maxCount={10}
                      onChange={(info) => {
                        setComponentState((prev) => {
                          return {
                            ...prev,
                            showAttachmentsPopover: false,
                          };
                        });
                        const tempFileList = [...info.fileList];
                        if (fileList?.length) {
                          handleChange({
                            ...value,
                            fileList: uniqueArray([
                              ...(fileList || []),
                              ...tempFileList,
                            ]),
                          });
                        } else {
                          handleChange({
                            ...value,
                            fileList: tempFileList,
                          });
                        }
                      }}
                      fileList={[]}
                      className="msg-attachment"
                      accept={UPLOAD_ACCEPTED_FILE_TYPES}
                    >
                      <HStack space={3} alignItems={'center'}>
                        <Feather
                          name="upload"
                          size={18}
                          color={Colors.Custom.Gray500}
                        />
                        <Text fontWeight={400}>
                          {intl.formatMessage({id: 'uploadNew'})}
                        </Text>
                      </HStack>
                    </Upload>
                  </VStack>
                }
              >
                <Pressable
                  onPress={() =>
                    setComponentState((prev) => {
                      return {
                        ...prev,
                        showAttachmentsPopover: true,
                      };
                    })
                  }
                  position={'absolute'}
                  top={3}
                  right={isSidecarContext? '16px' : '20px'}
                  flexDir={'row'}
                  justifyContent={'flex-end'}
                  alignItems={'center'}
                >
                  <Tooltip
                    title={intl.formatMessage({
                      id: 'taskAttachmentUploadSupportAndSize',
                    })}
                  >
                    <Icon
                      name="paperclip"
                      size={18}
                      color={Colors.FoldPixel.GRAY400}
                    />
                  </Tooltip>
                </Pressable>
                </Popover>
              </View>
            </View>
            {(fileListWithoutDeleted?.length || 0) > 0 && (
              <AttachmentsItemList
                handleChange={handleChange}
                value={value}
                fileList={manageAttachmentsListData(fileList || [])}
              />
            )}
          </VStack>
        </FormControl>
      </View>
    </>
  );

  const handleAddSubtasks = () => {
    const newSubTask = {
      id: '',
      tempId: uuidv4(),
      title: '',
      description: '',
      assigneeId: value.assignee?.value,
      assignee: value.assignee,
      assignedById: '',
      startDateTime: '',
      endDateTime: getDefaultDueDate().toString() || '',
      statusId: '',
      priorityId: '',
      isEscalated: false,
      contactId: null,
      userPoolId: null,
      isChecked: false,
    } as ISubTasks;

    handleSubTaskChange?.(ADD_SUBTASK_CONST.ADD_NEW, [newSubTask])
  }


  const isSubTaskAssigneeInPool = (selectedPool: IPoolInfo, subTasks: any[]) => {
    const poolUsersIds = selectedPool.userPoolUsers.map((user: any) => user?.userId)
    let allAssigneeOfSubTasksInTaskPool: boolean | undefined = true
    subTasks?.forEach((subTask) => {
      if (!(poolUsersIds.includes(subTask?.assignee?.key))) {
        allAssigneeOfSubTasksInTaskPool = false
        return
      }
    })
    return allAssigneeOfSubTasksInTaskPool
  }

  const handleAddLocalComment = (comment: string) => {
    const newComment : ITaskCommentsList =  {
      id: '',
      taskId: '',
      isModified: false,
      createdOn: new Date().toISOString(),
      createdBy: currentUser.details.uuid,
      isDeleted: false,
      tempId: uuidv4(),
      userName: currentUser.details.available_name,
      comment: comment
    }

    handleCommentChange?.(ADD_COMMENT_CONST.ADD_NEW, newComment)
  }

  const handleDeleteLocalComment = (comment: ITaskCommentsList) => {
    handleCommentChange?.(ADD_COMMENT_CONST.DELETE, comment)
  }

  const getMessageToDisplay = () => {
    const defaultAssignee = stateForHandlingSubTaskAssigneeAfterPoolChange?.temporaryUserPoolSelection?.defaultAssignee;
    const isSubTaskAssigneeChange = stateForHandlingSubTaskAssigneeAfterPoolChange?.temporaryUserPoolSelection?.isSubtaskAssigneeChange;
    const isAssigneeInPool = stateForHandlingSubTaskAssigneeAfterPoolChange?.temporaryUserPoolSelection?.isAssigneeInPool;
    const taskAssigneeChangeToCurrentUser = stateForHandlingSubTaskAssigneeAfterPoolChange?.temporaryUserPoolSelection?.taskAssigneeChangeToCurrentUser;
    const subtaskAssigneeChangeToCurrentUser = stateForHandlingSubTaskAssigneeAfterPoolChange?.temporaryUserPoolSelection?.subtaskAssigneeChangeToCurrentUser;
    return getMessage(defaultAssignee, isSubTaskAssigneeChange, isAssigneeInPool, taskAssigneeChangeToCurrentUser, subtaskAssigneeChangeToCurrentUser)
  }

  const renderSubTasks = () => {
    return (
      <>
        <Pressable
          key={task?.id}
          disabled={isAssigneeTypePatientOrProspect()}
          width={'250px'}
          mt={2}
          onPress={() => {
            if (!isAssigneeTypePatientOrProspect()) {
              handleAddSubtasks();
            } else {
              alert(intl.formatMessage({ id: 'subTaskAddErrorMsg' }));
            }
          }}
        >
          <HStack>
            <PlusIcon />
            <Text
              cursor={'pointer'}
              size="smMedium"
              fontWeight={'400'}
              color={Colors.Custom.mainPrimaryPurple}
            >
              {intl.formatMessage({
                id: 'addSubTasks',
              })}
            </Text>
          </HStack>
        </Pressable>
      </>
    );
  };

  const getRestrictPatientOrLeadSelectionTo = () => {
    if (
      subTasks?.length ||
      !props?.restrictPatientOrLeadSelectionTo?.value?.length ||
      !props?.restrictPatientOrLeadSelectionTo?.contactData?.patient
    ) {
      return undefined;
    }
    return props?.restrictPatientOrLeadSelectionTo;
  };

  const isPatientAndLeadSearchDisabled = (): boolean => {
    const isCarePlanTask = !!props.linkedCarePlanId
    const isNoteContextTask = linkPatientNote && linkPatientNote?.resourceId ? true : false;
    if (isNoteContextTask || isCarePlanTask) {
      return true;
    }
    return (subTasks?.length || !!props?.restrictPatientOrLeadSelectionTo)  ? true : false;
  }

  const renderAssigneeTo = () => (
    <>
      <View style={{ width: '100%' }}>
        {isEmployer ? (
          <UserAutoComplete
            // isDisabled={props.isAssigneeDisabled}
            labelText={'assignee'}
            isShowError={value.showErrors}
            selectedValue={value.assignee as IUserSearch}
            excludeOtherEmployers={true}
            onChange={(selectedUser) => {
              handleChange({
                ...value,
                assignee: selectedUser as IUserPatientSearchItem,
              });
            }}
            usersData={isEmployer ? taskPoolData?.accountUsers : []}
          />
        ) : (
          <PatientSearchAndSelect
            className={'custom-task-style'}
            accountLocationUuids={value?.selectedAccountLocations?.map((location) => location?.uuid)}
            resourceType={MAIN_MENU_CODES.TASKS}
            isDisabled={isMultiTenancyEnabled && !value?.selectedAccountLocations?.length}
            isRequired={true}
            showUserPendingTasksCount={true}
            isInvalid={value.showErrors && !value.assignee?.value}
            closeSelect={stateForHandlingPoolAfterTaskAssigneeChange?.closeSelect}
            setCloseSelect={() => { setStateForHandlingPoolAfterTaskAssigneeChange((prev) => { return { ...prev, closeSelect: false } }) }}
            labelColor={Colors.Custom.alertsDescriptionColor}
            showCurrentUserAtTop={taskPoolData?.selectedPool?.id ? false : true}
            label={'Assign to'}
            isProspect={true}
            allowClear={false}
            disableLeadsSearch={true}
            disablePatientSearch={isPatientAndLeadSearchDisabled()}
            showEmail
            showPhoneNumber
            // isDisabled={props.isAssigneeDisabled}
            customStyle={{borderRadius: 4, backgroundColor: Colors.Custom.White}}
            showErrorBorder
            showError={value.showErrors && !value.assignee?.value}
            placeholder="Search Staff or Member"
            value={value.assignee}
            {...(isSidecarContext && {dropdownPopupMinWidth: 400} )}
            showPatientProfileLink={
              !!value.assignee?.value &&
              isAssigneeTypePatientOrProspect() &&
              !isSelectedAssigneeAndDefaultAssigneeSame
            }
            onPatientProfileIconClick={() => {
              if (!isActiveContact(value?.assignee?.details)) {
                const message = getInActiveContactError(value?.selectedContact);
                notification.error({
                  message,
                  placement: 'top'
                });
                return;
              }
              if (isEmployer) {
                setSelectedRowContactData(
                  value.assignee?.details as IUserPatientSearchItem
                );
                setSelectedViewCode({
                  code: RIGHT_SIDE_CONTAINER_CODE.CONTACT_DETAILS_VIEW,
                });
              } else if (
                value.assignee?.details?.contactType?.contactType?.id ===
                contactTypeId ||
                value.assignee?.details?.contactType?.contactType?.code ===
                PERSON_TYPES.CUSTOMER
              ) {
                props?.openContactInWindowOrOpenContactIdDrawer?.(value.assignee?.details?.id);
              } else if (
                value.assignee?.details?.contactType?.contactType?.id ===
                leadContactTypeId
              ) {
                props?.navigateOrOpenLeadInWindow?.(value.assignee?.details?.id);
              } else {
                props?.navigateOrOpenLeadInWindow?.(value.assignee?.details?.id);
              }
            }}
            poolSelected={props?.value?.taskPool?.key ? true : false}
            selectedPoolUsers={taskPoolData?.selectedPoolUsers}
            defaultAssigneeInSelectedPool={taskPoolData?.defaultAssignee}
            onChange={(participants: any, data: any) => {
              const contactIdValue =
                participants?.label?.props?.contactData?.uuid ||
                participants?.value;
              const contactIdKey = contactIdValue || participants?.key;
              const assignee = {
                key: contactIdKey,
                value: contactIdValue,
                label: participants?.label?.props?.contactData?.name,
                type: data?.type,
                details: participants?.label?.props?.contactData,
              }
              data?.type !== ParticipantType.staff || !taskPoolData?.selectedPool?.id || taskPoolData?.selectedPool.userPoolUsers?.some((user) => user?.userId === contactIdKey) ? handleAssigneeChange(assignee, data?.type) :
                setStateForHandlingPoolAfterTaskAssigneeChange({
                  showAlertOfEmptyingTaskPool: true,
                  temporaryData: {
                    assignee: assignee,
                    type: data?.type
                  }
                })
            }}
            employerId={employerId}
            restrictPatientOrLeadSelectionTo={getRestrictPatientOrLeadSelectionTo()}
            handleOnClear={true}
            isSidecarTaskLabelSelect={isSidecarContext}
          />
        )}
      </View>
    </>
  );
  const renderDueDate = () => (
    <>
      <ModalActionDatePicker
        isRequired={true}
        label={'dueDate'}
        labelStyles={{color: Colors.Custom.alertsDescriptionColor}}
        leftMargin={'0'}
        format={DISPLAY_DATE_FORMAT}
        onChange={(data: any, dateString) => {
          handleChange({
            ...value,
            dueDate: dateString,
          });
        }}
        value={getDefaultDueDate()}
        extraStyle={{flex: 1}}
        customStyle={{ flex: 1, heigh: 40, borderRadius: 4, borderColor: Colors.FoldPixel.GRAY150, marginBottom: -1, marginTop: 0 }}
        allowClear={false}
        isSidecarTaskLabelSelect={isSidecarContext}
      />
    </>
  );
  const renderPriority = () => (
      <ModalActionAntSelect
        className={'custom-task-style'}
        allowClear={true}
        leftMargin={'0'}
        showSearch={false}
        labelInValue={true}
        filterOption={false}
        value={value.priority}
        isRequired={true}
        isInvalid={value.showErrors && !value.priority}
        label={'Priority'}
        placeholder={'Priority'}
        onChange={(data: any) => {
          handleChange({
            ...value,
            priority: data,
          });
        }}
        data={taskPriorityMlov}
        optionProps={{
          key: 'id',
          value: 'code',
          label: 'value',
        }}
        extraStyle={{ flex: 1, fontColor: Colors.Custom.alertsDescriptionColor}}
        customStyle={{backgroundColor: Colors.Custom.White, borderRadius: 4, borderColor: Colors.Custom.alertsDescriptionColor, height: 40}}
        selectStyle={{fontColor: Colors.FoldPixel.GRAY400}}
        isSidecarTaskLabelSelect={isSidecarContext}
      />
  );

  const isDisabledMemberField = () => {
    const isTaskLinkWithAnotherEntity = !!task?.subjectId && !!task?.subjectTypeId;
    const isCarePlanTask = !!props.linkedCarePlanId
    const isNoteContextTask = !!linkPatientNote && !!linkPatientNote?.resourceId;
    const isOrderTask = task?.orders && task?.orders?.length > 0;
    const isCareJourneyTask = task && task.patientCareJourney?.startDateTime ? true : false;
    const isSidecar = isSidecarContext && value.customer?.label;
    if (isTaskLinkWithAnotherEntity || isNoteContextTask || isOrderTask || isCareJourneyTask || isCarePlanTask || isSidecar) {
      return true;
    }
    return isAssigneeTypePatientOrProspect() || !selectedAccountLocationCheck;
  }
  
  const renderMember = () => (
    <View marginBottom={0}>
      <PatientSearchAndSelect
        className={'custom-task-style'}
        labelColor={Colors.Custom.alertsDescriptionColor}
        label={'Member'}
        labelInfo={intl.formatMessage({
          id: 'memberContextInfoMsg',
        })}
        isProspect={true}
        isDisabled={isDisabledMemberField()}
        disableUserSearch
        placeholder="Search member"
        customStyle={{borderRadius: 4, borderColor: Colors.Custom.alertsDescriptionColor, marginBottom: 0}}
        value={value.customer?.label || props?.restrictPatientOrLeadSelectionTo?.label}
        isSidecarTaskLabelSelect={isSidecarContext}
        showPatientProfileLink={!isAssigneeTypePatientOrProspect() && value.selectedContact?.id}
        {...(isSidecarContext && {dropdownPopupMinWidth: 400} )}
        resourceType={MAIN_MENU_CODES.TASKS}
        accountLocationUuids={value?.selectedAccountLocations?.map((location) => location?.uuid)}
        onPatientProfileIconClick={() => {
          if (!isActiveContact(value?.assignee?.details)) {
            const message = getInActiveContactError(value?.selectedContact);
            notification.error({
              message,
              placement: 'top'
            });
            return;
          }
          if (isEmployer) {
            setSelectedRowContactData(value.selectedContact);
            setSelectedViewCode({
              code: RIGHT_SIDE_CONTAINER_CODE.CONTACT_DETAILS_VIEW,
            });
          } else if (
            value.selectedContact?.contactType?.contactType?.code ===
            PERSON_TYPES.CUSTOMER
          ) {
            props?.openContactInWindowOrOpenContactIdDrawer?.(value?.selectedContact?.id?.toString());
          } else if (
            value.selectedContact?.contactType?.contactType?.code ===
            PERSON_TYPES.LEAD
          ) {
            props?.navigateOrOpenLeadInWindow?.(value.selectedContact?.id);
          } else {
            props?.navigateOrOpenLeadInWindow?.(value.selectedContact?.id);
          }
        }}
        onChange={(participants: any, data: any) => {
          const contactIdValue =
            participants?.label?.props?.contactData?.uuid ||
            participants?.value;
          const contactIdKey = contactIdValue || participants?.key;
          handleChange({
            ...value,
            selectedContact: participants?.label?.props?.contactData,
            customer: {
              key: contactIdKey,
              value: contactIdValue,
              label: participants?.label?.props?.contactData?.name,
              type: data?.type,
              details: participants?.label?.props?.contactData,
            },
          });
        }}
        employerId={employerId}
        disableChangeOfSelect={!!props?.restrictPatientOrLeadSelectionTo}
      />
      {!value.customer?.key && !(!!props?.restrictPatientOrLeadSelectionTo?.label) &&
      <Text py={1} fontSize="xs" color={Colors.Custom.Gray500}>
        {intl.formatMessage({ id: 'memberFieldContext' })}
      </Text>
      }
    </View>
  );

  const isTaskPoolDisabled = () => {
    return isAssigneeTypePatientOrProspect() || !selectedAccountLocationCheck;
  }

  const renderTaskPool = () => (
    <>
      {!isEmployer && (
        <>
          <TaskPoolSearchAndSelect
            className={'custom-task-style'}
            isDisabled={isTaskPoolDisabled()}
            allowClear={true}
            filterOption={true}
            labelColor={Colors.Custom.alertsDescriptionColor}
            value={value.taskPool || undefined}
            isRequired={false}
            label={'addTaskTaskPoolLabel'}
            labelInfo={intl.formatMessage({ id: 'addTaskTaskPoolInfoMsg' })}
            placeholder={intl.formatMessage({
              id: 'addTaskTaskPoolPlaceholder',
            })}
            onChange={handleChangeInTaskPool}
            userPools={taskPoolData?.currentUserPools}
            otherPools={taskPoolData?.otherUserPools}
            assigneeId={value.assignee?.key}
            customStyle={{height: 40, borderRadius: 4, backgroundColor: Colors.Custom.White, marginBottom: 0}}
            isSidecarTaskLabelSelect={isSidecarContext}
            selectedAccountLocationUuid={value?.selectedAccountLocations?.[0]?.uuid}
          />
        </>
      )}
    </>
  );
  const renderCategory = () => (
    <>
      <ModalActionAntSelect
        disabled={isAssigneeTypePatientOrProspect()}
        isDisabled={isAssigneeTypePatientOrProspect()}
        allowClear={false}
        showSearch={false}
        labelInValue={true}
        filterOption={false}
        value={value.taskDisplayCategoryId}
        leftMargin={'0'}
        isRequired={false}
        label={'addTaskAddUnderCategory'}
        labelInfo={'addTaskAddUnderCategoryInfoMsg'}
        placeholder={'Select Category'}
        onChange={(selectedItem: any) => {
          if (selectedItem.value) {
            handleChange({
              ...value,
              taskDisplayCategoryId: selectedItem.value,
            });
          }
        }}
        data={TASK_CATEGORY.map((taskCategoryItem: any) => {
          const typeCategoryId = getMlovId(
            mlovData.CARE_STUDIO_MLOV,
            'TaskDisplayCategory',
            taskCategoryItem.code
          );
          const checkUserSpecificTypeValue: any =
            value.userTaskDisplayCategory?.filter(
              (dataItem: any) =>
                dataItem.taskDisplayCategoryId === typeCategoryId
            );
          const statusTypeValue =
            checkUserSpecificTypeValue && checkUserSpecificTypeValue.length
              ? checkUserSpecificTypeValue[0]['categoryValue']
              : getMlovValue(
                mlovData.CARE_STUDIO_MLOV,
                'TaskDisplayCategory',
                taskCategoryItem.code
              );

          return { ...taskCategoryItem, label: statusTypeValue };
        })}
        optionProps={{
          key: 'code',
          value: 'value',
          label: 'label',
        }}
        extraStyle={{ flex: 1, fontColor: Colors.Custom.alertsDescriptionColor}}
        customStyle={{borderRadius: 4}}
      />
    </>
  );

  const [getTaskLabels] = useLazyQuery(GetLabelsByLabelTitleAndTypeId, {
    fetchPolicy: 'no-cache'
  });
  const [createLabel] = useMutation(CreateLabel);
  const [createLabelType] = useMutation(CreateLabelType);

  const onSearchTaskLabel = async (searchString: string) => {
    try {
      const taskLabelParams = {
        limit: 10,
        offset: 0,
        labelTypeId: mlov.id,
        search: `%${searchString?.trim()}%`,
      };
      const apiResponse = await getTaskLabels({ variables: taskLabelParams});
      const taskLabels = apiResponse?.data?.labels || [];
      setTaskLabelData(prev => {
        return {
          ...prev,
          loading: false,
          taskLabelsList: taskLabels,
        }
      });
    } catch(error) {
      setTaskLabelData(prev => { return { ...prev, loading: false }});
    }
  };

  const createNewLabel = async () => {
    const searchedTag = taskLabelData?.searchString?.trim();
    try {
      const createLabelBody = [{
        accountId: accountId,
        color: DEFAULT_TASK_LABEL_COLOR,
        description: '',
        title: searchedTag,
        labelTypeId: mlov?.id
      }]
      const response = await createLabelAndLabelType(createLabelBody);
      if (response?.data?.length) {
        const tag = response?.data?.[0];
        const temp = {
          id: tag?.id,
          uuid: tag?.uuid,
          title: tag?.title,
          color: tag?.color,
          description: tag?.description
        }
        const currentLabelData = [...taskLabelData.taskLabelsList || []];
        const selectedLabelData = [...taskLabelData.selectedLabels || [], tag?.uuid];
        currentLabelData.push(temp);
        setTaskLabelData(prev => {
          return {
            ...prev,
            searchString: '',
            taskLabelsList: [...currentLabelData],
            selectedLabels: selectedLabelData,
          }
        })
        handleChange({
          ...value,
          selectedLabels: selectedLabelData,
        });
        onSearchTaskLabel('');
      }
    } catch (error: any) {
      const message = error?.response?.data?.message || error?.networkError?.result?.message
      notification.error({
        message: message || intl.formatMessage({id: 'apiErrorMsg'}),
        duration: 5.0,
        placement: 'topRight'
      });
    }
  }

  const getLabelOptions = () => {
    if (taskLabelData?.taskLabelsList?.length) {
      return (taskLabelData?.taskLabelsList || []).map((label: ILabelTask) => {
        return <Option key={label.uuid} value={label.uuid}>
          {label?.title}
        </Option>
      });
    } else {
      if (taskLabelData?.searchString) {
        return (<Option key={'addLabel'} value={'addLabel'}>
        <Pressable onPress={() => {
          createNewLabel();
        }} >
          {`Add Label - ${taskLabelData?.searchString}`}
        </Pressable>
      </Option>)
      } else {
        return <Option key={'noData'} value={'noData'}>
          {intl.formatMessage({ id: 'NoDataSelect' })}
        </Option>
      }
    }
  }

  const checkStringExistInTaskLabels = () => {
    if(taskLabelData?.taskLabelsList?.length){
      return taskLabelData?.taskLabelsList?.some((tasklabel)=> tasklabel.title?.includes(taskLabelData?.searchString || ''))
    }
    return false
  }

  const renderTaskLabel = () => {
    return (
        <View>
          <View style={{marginBottom: 8}}>
            <DisplayText
              size={'smMedium'}
              extraStyles={{
                color: Colors.Custom.alertsDescriptionColor,
                fontWeight: 500,
                fontSize: 14,
              }}
              textLocalId={'labels'}
            />
          </View>
          <View style={{marginBottom: 12}}>
            <Select
              className='custom-task-style'
              disabled={labelsLoading}
              mode="multiple"
              allowClear
              placeholder={labelsLoading ? "Loading..." : "Search Labels"}
              showSearch={true}
              value={
                (!labelsLoading && taskLabelData.selectedLabels?.length)
                  ? taskLabelData.selectedLabels
                  : []
              }
              searchValue={taskLabelData.searchString}
              filterOption={false}
              bordered={true}
              dropdownStyle={{
                borderRadius: 8,
                ...(isSidecarContext ? {minWidth: 280} : {})
              }}
              onChange={(selectedLabels) => {
                const tempData = selectedLabels?.filter((item: string) => {
                  return item != 'noData';
                });
                setTaskLabelData((prev) => {
                  return {
                    ...prev,
                    searchString: '',
                    selectedLabels: tempData,
                  };
                });
                handleChange({
                  ...value,
                  selectedLabels: tempData,
                });
              }}
              onSearch={(value)=> {
                setTaskLabelData((prev) => {
                  return {...prev, searchString: value.trimStart()};
                });
                getSearchtaskLabel(value)
              }}
              onInputKeyDown={(e)=>{
                if(e.key === "Enter" && !checkStringExistInTaskLabels()){
                  e.stopPropagation()
                }
              }}
              loading={taskLabelData.loading}
              notFoundContent={intl.formatMessage({
                id: 'NoDataSelect',
              })}
              onBlur={() => {
                setTaskLabelData(prev => {
                  return {
                    ...prev,
                    taskLabelsList: [],
                    searchString: '',
                  }
                });
              }}
            >
              {getLabelOptions()}
            </Select>
          </View>
        </View>
    );
  };

  const getJourneyDates = (careJourney: {
    startDateTime: string;
    endDateTime: string;
  }) => {
    let date = '';
    if (careJourney.startDateTime && careJourney.endDateTime) {
      date = getDateStrFromFormat(
        careJourney.startDateTime,
        DATE_FORMATS.DISPLAY_DATE_FORMAT
      );

      if (
        isCurrentDateInFutureComparedToOther(
          new Date(),
          careJourney.endDateTime
        )
      ) {
        date +=
          ' - ' +
          getDateStrFromFormat(
            careJourney.endDateTime,
            DATE_FORMATS.DISPLAY_DATE_FORMAT
          );
      } else {
        date += ' - Present';
      }
    }
    return date;
  };

  const optedOutJourneyElement = (patientCareJourney: any) => {
    if (patientCareJourney?.statusId && patientCareJourney?.statusReasonCode) {
      const statusReasonCode = patientCareJourney?.statusReasonCode;
      const code = getMlovCodeFromId(mlovs, patientCareJourney?.statusId);
      if (code === PATIENT_CARE_JOURNEY_STATUS_CODE.PAUSED && statusReasonCode === 'PATIENT_OPTED_OUT') {
        return <><Text fontWeight={400} color={Colors.FoldPixel.STATUS_ERROR}>Opted Out</Text></>;
      }
      return <></>;
    }
    return <></>;
  }

  const renderPatientJourneyTask = () => {
    const journeyTitle = task?.patientCareJourney?.title || task?.patientCareJourney?.careJourney?.title;
    if (task && task.patientCareJourney && task.patientCareJourney.careJourney && journeyTitle) {
        return (
        <VStack>
           <DisplayText
            size={'smMedium'}
            extraStyles={{
              color: Colors.Custom.alertsDescriptionColor,
              fontWeight: '400',
              fontSize: 14,
            }}
            textLocalId={'Linked Journey'}
          />
          <VStack
            padding={2}
            paddingLeft={0}
            backgroundColor={Colors.FoldPixel.GRAY50}
            borderWidth={1}
            borderColor={Colors.FoldPixel.GRAY100}
            borderRadius={6}
            marginY={1}
          >
            <Stack direction={'row'} my={0.5} alignItems="center">
              <Text color={Colors.Custom.Gray500} fontSize={14}>
                {getJourneyDates(task?.patientCareJourney)}
              </Text>
              <Text color={Colors.Custom.Gray500}> • </Text>
              {optedOutJourneyElement(task.patientCareJourney)}
            </Stack>
            <Text color={Colors.FoldPixel.GRAY400} fontSize={14}>{journeyTitle}</Text>
          </VStack>
        </VStack>
      );
    }
    return <></>;
  }

  const isVitalTask = isTaskWithType(props?.task || {} as ITask, EntityType.VITAL);
  const isFormTask = isTaskWithType(props?.task || {} as ITask, EntityType.FORM);

  const canShowMarkAsComplete = ()=> {
    if (isVitalTask || isFormTask){
      return false;
    } else {
      return taskConfig?.canMarkAsComplete
    }
  }

  const GetDivider = (props: {height?: number}) => {
    return (
      <View
        style={{
          width: 1.5,
          height: props.height || 16,
          marginHorizontal: 8,
          backgroundColor: Colors.Custom.Gray400,
        }}
      />
    );
  };

  const backBtn = () => ({
    show:
      (selectedViewCode.code === COMMON_ACTION_CODES.PREVIEW)
        ? false
        : true,
    id: 1,
    btnText: isSidecarContext ? 'back' : 'cancel',
    textColor: Colors.Custom.mainSecondaryBrown,
    variant: BUTTON_TYPE.SECONDARY,
    isTransBtn: false,
    onClick: () => {
      onCancel?.();
    },
  });

  return (
    <Drawer
      className={isSidecarContext ? 'custom-task-drawer-sidecar' : 'custom-task-drawer-web'}
      destroyOnClose
      placement="right"
      open={isVisible}
      closable
      mask={isSidecarContext ? false : true}
      width={
        isSidecarContext ? '100%' : (isIPadScreen || isIPadMiniScreen ? '70%' : smallWindow ? '60%' : '50%')
      }
      title={
        <ModalActionTitle
          title={'task'}
          titleSize={24}
          titleColor={'#000000'}
          // buttonList={[
          //   {...backBtn(), show: !isSidecarContext},
          //   {
          //     show:
          //       selectedViewCode.code === COMMON_ACTION_CODES.PREVIEW
          //         ? false
          //         : true,
          //     id: 1,
          //     btnText: isSidecarContext ? 'back' : 'cancel',
          //     textColor: Colors.Custom.mainSecondaryBrown,
          //     variant: BUTTON_TYPE.SECONDARY,
          //     isTransBtn: false,
          //     onClick: () => {
          //       onCancel?.();
          //     },
          //   },
          //   {
          //     id: 2,
          //     isLoading: value.markAsCompleteLoading,
          //     show: canShowMarkAsComplete(),
          //     btnText: 'MarkAsCompleted',
          //     textColor: Colors.secondary[800],
          //     variant: BUTTON_TYPE.PRIMARY,
          //     isTransBtn: false,
          //     onClick: () => {
          //       onMarkAsComplete?.(
          //         task || ({} as ITask),
          //         BottomViewAction.markAsComplete,
          //         {}
          //       );
          //     },
          //   },
          //   {
          //     show:
          //       selectedViewCode.code === COMMON_ACTION_CODES.PREVIEW
          //         ? false
          //         : true,
          //     id: 3,
          //     btnText: props.saveBtnText || 'save',
          //     textColor: Colors.Custom.mainPrimaryPurple,
          //     variant: BUTTON_TYPE.PRIMARY,
          //     isTransBtn: false,
          //     // leftIcon: '',
          //     onClick: () => {
          //       onAddTask?.();
          //     },
          //     isLoading: value.loading,
          //   },
          //   {
          //     show:
          //       selectedViewCode.code === COMMON_ACTION_CODES.PREVIEW
          //         ? true
          //         : false,
          //     id: 4,
          //     btnText: 'done',
          //     size: 'sm',
          //     textColor: Colors.Custom.mainPrimaryPurple,
          //     variant: BUTTON_TYPE.PRIMARY,
          //     isTransBtn: false,
          //     onClick: () => {
          //       // props.onComplete(taskDetail, taskDisplayCategoryId);
          //       // setSelectedViewCode({
          //       //   code: '',
          //       // }); todo
          //     },
          //   },
          // ]}
          rightButton={
            <View
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <FoldButton
                customProps={{
                  btnText: props.saveBtnText || 'Save',
                }}
                nativeProps={{
                  onPress:() => {
                    onAddTask?.();
                  },
                  isLoading: value.loading,
                  backgroundColor: isFormValid ? Colors.Custom.Primary300 : Colors.Custom.Gray200,
                  _text: {
                    fontSize: 14,
                    lineHeight: 16.8,
                    fontWeight: 'bold',
                    color: isFormValid ? Colors.Custom.White : Colors.Custom.Gray400,
                  },
                  leftIcon: <Icon name="check" color={isFormValid ? Colors.Custom.White : Colors.Custom.Gray400} size={15} />,
                  disabled: !isFormValid,
                }}
              />
              <GetDivider />
              <Pressable
                onPress={() => {
                  onCancel?.();
                }}
                disabled={value.loading}
              >
                <Icon name="close" size={20} color={Colors.Custom.Gray500} />
              </Pressable>
            </View>
          }
        />
      }
    >
      <View mx={isSidecarContext? 0 : 6} mt={isSidecarContext? 0 :3}>
      <VStack>
        {loading ? (
          <>
            <Skeleton.Text />
            <Skeleton.Text my={2} />
            <Skeleton.Text />
          </>
        ) : (
          <>
            {selectedViewCode.code !== COMMON_ACTION_CODES.PREVIEW ? (
              <>
                <VStack mx={0} space={4}>
                  <View style={{ width: '24%', paddingRight: 1 }}>
                    {renderTaskStatus()}
                  </View>
                  {isMultiTenancyEnabled && renderAccountLocations()}
                  {renderTitle()}
                  {renderDescription()}
                  {
                    !!props.linkedCarePlanId && (
                      <PatientLinkCarePlanCard
                        assignByUserName={task?.assignedByUser?.name}
                        key={props.linkedCarePlanId}
                        linkedCarePlanId={props.linkedCarePlanId}
                        onCarePlanLinkClick={() => {
                          const url = `/#/members/patient/${task?.contact?.id}/carePlan`;
                          if (isSidecarContext) {
                            onChangeCarePlanVisible?.(true);
                          }
                          else{
                           window.open(url, '_blank');
                          }
                        }}
                      />
                    )
                  }
                  {!!linkPatientNote && !!linkPatientNote?.resourceId && (
                    <>
                      <PatientLinkNoteCard
                        data={linkPatientNote}
                        onNoteLinkClick={() => {
                          if(isSidecarContext){
                            redirectToNewTab(`/members/patient/${linkPatientNote?.contactId}/notes/${linkPatientNote?.resourceId}`);
                          }
                          else{
                            navigate(`/members/patient/${linkPatientNote?.contactId}/notes/${linkPatientNote?.resourceId}`);
                          }
                          !!onNoteRedirect && onNoteRedirect();
                        }}
                        accountLocationUuid={carePlanProps?.accountLocationUuid}
                      />
                    </>
                  )}
                  {renderPatientJourneyTask()}
                  <TaskExtraDetailsView task={task} accountUsers={accountUsers} />
                  <VStack>
                    {(subTasks || [])?.length > 0 && (
                      <>
                        <HStack alignItems={'center'} ml={1}>
                          <DisplayText
                            size={'mdSemibold'}
                            extraStyles={{
                              fontWeight: 600,
                              fontSize: 16,
                            }}
                            textLocalId={'subTasks'}
                          />
                          <Pressable
                            ml={2}
                            key={task?.id}
                            onPress={() => {
                              setExpandSubtask(!expandSubtask);
                            }}
                          >
                            <View size={18}>
                              {expandSubtask ? (
                                <ArrowDownSvg customStrokeColor="black"></ArrowDownSvg>
                              ) : (
                                <ArrowRightSvg customStrokeColor="black"></ArrowRightSvg>
                              )}
                            </View>
                          </Pressable>
                          <Text
                            fontWeight={600}
                            fontSize={14}
                            color={Colors.Custom.Gray500}
                            ml={5}
                          >
                            {`${
                              subTasks?.filter((item) => item.isChecked)?.length
                            }/${subTasks?.length}`}
                          </Text>
                        </HStack>
                        {expandSubtask && (
                          <>
                            {subTasks?.map((data, index) => {
                              return (
                                <View
                                  key={(data?.id || data?.tempId || '') + index}
                                  ref={
                                    (data?.id || data?.tempId) ===
                                    task?.subTaskIdToScroll
                                      ? subTaskRef
                                      : undefined
                                  }
                                >
                                  <AddSubTasks
                                    key={index}
                                    subTaskItem={data}
                                    handleSubTaskChange={handleSubTaskChange}
                                    isInvalid={value.showErrors && !data?.assignee?.key && data?.title?.trim()?.length > 0}
                                    showErrorBorder={value.showErrors && !data?.assignee?.key}
                                    isEmployer={isEmployer}
                                    taskPoolData={taskPoolData}
                                    value={props?.value}
                                    employerId={employerId}
                                    subTaskLoading={subTaskLoading}
                                    isEditTask={isEditTask}
                                    emptyTaskPoolField={() => {
                                      setTaskPoolData(prev => {
                                        return {
                                          ...prev,
                                          selectedPool: undefined,
                                          selectedPoolUsers: [],
                                          defaultAssignee: undefined,
                                        }
                                      });
                                      handleChange({ ...value, taskPool: undefined });
                                    }
                                    }
                                    task={task}
                                  />
                                </View>
                              );
                            })}
                          </>
                        )}
                      </>
                    )}

                    {!isAssigneeTypePatientOrProspect() ? (
                      renderSubTasks()
                    ) : (
                      <></>
                    )}
                  </VStack>

                  <HStack space={2} style={{justifyContent: 'space-around'}}>
                      <View style={{ width: '49%', paddingRight: 1 }}>
                        {renderAssigneeTo()}
                      </View>
                      <View style={{ width: '49%', paddingRight: 1 }}>
                        {renderTaskPool()}
                        <DisplayText
                          extraStyles={{
                            color: Colors?.Custom?.Gray400,
                            // fontWeight: 600,
                            fontSize: 12,
                          }}
                          textLocalId={'Does not apply when member is assignee'}
                        />
                      </View>
                  </HStack>

                  <HStack space={2} style={{justifyContent: 'space-around'}}>
                    <View style={{width: '49%', paddingRight: 1}}>
                      {renderDueDate()}
                    </View>
                    <View style={{width: '49%', paddingRight: 1}}>
                      {renderPriority()}
                    </View>
                  </HStack>


                    <>
                      <HStack
                        space={2}
                        style={{justifyContent: 'space-around'}}
                      >
                        <View style={{ width: '49%', paddingRight: 1 }}>
                          {renderMember()}
                        </View>
                        <View style={{width: '49%', paddingRight: 1}}>
                          {renderTaskLabel()}
                        </View>

                      </HStack>
                      {/* <HStack space={2} style={{justifyContent: 'flex-start'}}>
                        <View style={{width: '49%', paddingRight: 1}}>
                          {renderCategory()}
                        </View>
                      </HStack> */}
                    </>
                    <Segmented
                      options={tabOptions}
                      value={selectedTab?.code}
                      onChange={(value) => {
                        const selected = TASK_VIEW_TABS.find(
                          (tab) => tab.code === value
                        );
                        setSelectedTab(selected);
                      }}
                      style={{
                        marginTop: 2,
                        marginBottom: -4,
                        width: 'fit-content',
                        maxWidth: '35%',
                        height: 32,
                        borderRadius: 4,
                      }}
                      onResize={undefined}
                      onResizeCapture={undefined}
                    />
                  {accountUsers?.length > 0 && selectedTab?.code === TaskViewTabsCode?.ACTIVITY && (
                    <View ref={targetRef}>
                      <AddOrUpdateCommentForTask
                        accountUsers={accountUsers}
                        comments={comments}
                        handleDeleteLocalComment={handleDeleteLocalComment}
                        handleAddLocalComment={handleAddLocalComment}
                        task={task}
                        onCommentChange={props?.onCommentChange}
                        isSaveClick={props?.isSaveClick}
                        handleCommentErrorAction={props?.handleCommentErrorAction}
                        onCommentButtonClick={props?.onCommentButtonClick}
                        showCommentError={props?.showCommentError}
                        isNeedToScrollDown={props?.isNeedToScrollDown}
                        showEditCommentError={props?.showEditCommentError}
                      />
                    </View>
                  )}

                {!isEmployer && value.assignee?.value && selectedTab?.code === TaskViewTabsCode?.AUTOMATION && (
                  <FoldPermitCan
                  resource={MAIN_MENU_CODES.AUTOMATION}
                  action={USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code}
                  component={<WorkFlowListForTasks
                    addUrl={CUSTOM_AUTOMATION_URL.ADD_TASK}
                    key={value?.assignee?.type}
                    title={ENTITY_EVENTS_TITLE_SUBTITLE.ADD_TASK_TITLE}
                    subtitle={ENTITY_EVENTS_TITLE_SUBTITLE.ADD_TASK_SUB_TITLE}
                    entityEventList={!!value?.assignee?.value && isAssigneeTypePatientOrProspect()  ? ENTITY_EVENTS_TYPE.ADD_TASK : ENTITY_EVENTS_TYPE.ADD_TASK_PROVIDER}
                    onValueChanage={(eventEntityList: any) => {
                      handleChange({
                        ...value,
                        workflowList: eventEntityList,
                      });
                    }}
                  />}
                  />
                  )}

                  {selectedViewCode.code === COMMON_ACTION_CODES.PREVIEW && (
                    <TaskDetailsPreview
                      task={selectedViewCode.task}
                      assignee={{
                        //value: currentUserUUID,
                        label: value.assignee?.label || '',
                        //key: currentUserUUID,
                      }}
                      locationId={value?.selectedAccountLocations?.[0]?.uuid}
                    />
                  )}
                  {selectedViewCode.code ===
                    RIGHT_SIDE_CONTAINER_CODE.CONTACT_DETAILS_VIEW && (
                    <MessagingContactDetailsDrawer
                      contactData={selectedRowContactData}
                      isDrawerVisible={true}
                      contactType={
                        selectedRowContactData?.contactType?.contactType
                          ?.code || PERSON_TYPES.CUSTOMER
                      }
                      onSideBarActionPerformed={(action?: any) => {
                        if (action == CONVERSATION_ACTION_CODES.DRAWER_CLOSE) {
                          setSelectedViewCode({
                            code: '',
                          });
                        }
                      }}
                      borderLessView={true}
                    />
                  )}
                </VStack>
              </>
            ) : null}
              <Modal
                title="Change Task Pool" open={stateForHandlingSubTaskAssigneeAfterPoolChange.showAlertOfSubTaskAssigneeChange}
                onCancel={() => {
                  setStateForHandlingSubTaskAssigneeAfterPoolChange((prev: any) => {
                    return {
                      ...prev,
                      showAlertOfSubTaskAssigneeChange: false,
                      temporaryUserPoolSelection: {
                        ...prev.temporaryUserPoolSelection,
                        subtaskAssigneeChangeToCurrentUser: false,
                        taskAssigneeChangeToCurrentUser: false,
                      }
                    }
                  })
                }}
                footer={null}
                bodyStyle={{ paddingTop: 12 }}
              >
                <View>
                  <Text size={'mdMedium'} >{getMessageToDisplay()}</Text>
                  <Button.Group style={{
                    marginTop: 12, display: 'flex',
                    justifyContent: 'flex-end',
                  }}>
                    <FoldButton
                      nativeProps={{
                        variant: BUTTON_TYPE.SECONDARY,
                        style: { margin: 3 },
                        onPress: () => {
                          setStateForHandlingSubTaskAssigneeAfterPoolChange((prev: any) => {
                            return {
                              ...prev,
                              showAlertOfSubTaskAssigneeChange: false,
                              temporaryUserPoolSelection: {},
                            }
                          })
                        },
                      }}
                      customProps={{
                        btnText: intl.formatMessage({
                          id: 'No',
                        }),
                        withRightBorder: false,
                      }}
                    ></FoldButton>
                    <FoldButton
                      nativeProps={{
                        variant: BUTTON_TYPE.PRIMARY,
                        style: { margin: 3 },
                        onPress: () => {
                          if (stateForHandlingSubTaskAssigneeAfterPoolChange.temporaryUserPoolSelection?.selectedPool) {
                            setTaskPoolData(prev => {
                              return {
                                ...prev,
                                selectedPoolUsers: stateForHandlingSubTaskAssigneeAfterPoolChange.temporaryUserPoolSelection?.selectedPoolUsers,
                                defaultAssignee: stateForHandlingSubTaskAssigneeAfterPoolChange.temporaryUserPoolSelection?.defaultAssignee,
                                assigneeChangedDueToChangeInTaskPool: stateForHandlingSubTaskAssigneeAfterPoolChange.temporaryUserPoolSelection?.assigneeChangedDueToChangeInTaskPool,
                                selectedPool: stateForHandlingSubTaskAssigneeAfterPoolChange.temporaryUserPoolSelection?.selectedPool
                              }
                            });
                            const assignee = stateForHandlingSubTaskAssigneeAfterPoolChange.temporaryUserPoolSelection.assignee
                            const taskPool = stateForHandlingSubTaskAssigneeAfterPoolChange.temporaryUserPoolSelection.taskPool
                            handleChange({ ...value, ...(!(stateForHandlingSubTaskAssigneeAfterPoolChange.temporaryUserPoolSelection?.isAssigneeInPool) && { assignee }), taskPool });
                            subTasks?.forEach((subTaskItem) => {
                              const isAssigneeOfSubTaskInPool = isSubTaskAssigneeInPool(stateForHandlingSubTaskAssigneeAfterPoolChange.temporaryUserPoolSelection?.selectedPool, [subTaskItem])
                              if (!isAssigneeOfSubTaskInPool) {
                                const updateAssignee = {
                                  ...subTaskItem,
                                  assignee: {
                                    key: assignee?.key,
                                    value: assignee?.value,
                                    label: assignee?.label,
                                    type: assignee?.type,
                                    details: assignee,
                                  },
                                };
                                handleSubTaskChange?.(
                                  ADD_SUBTASK_CONST.CHANGE_ASSIGNEE,
                                  updateAssignee
                                );
                              }
                            })
                          }
                          setStateForHandlingSubTaskAssigneeAfterPoolChange((prev: any) => {
                            return {
                              ...prev,
                              showAlertOfSubTaskAssigneeChange: false,
                              temporaryUserPoolSelection: {},
                            }
                          })
                        },
                      }}
                      customProps={{
                        btnText: intl.formatMessage({
                          id: 'Yes',
                        }),
                        withRightBorder: false,
                      }}
                    ></FoldButton>
                  </Button.Group>
                </View>
              </Modal>
              <Modal
                title="Change Task Pool" open={stateForHandlingPoolAfterTaskAssigneeChange.showAlertOfEmptyingTaskPool}
                onCancel={() => {
                  setStateForHandlingPoolAfterTaskAssigneeChange({
                    showAlertOfEmptyingTaskPool: false,
                    closeSelect: true,
                    temporaryData: {}
                  })
                }}
                footer={null}
                bodyStyle={{ paddingTop: 12 }}
              >
                <View>
                  <Text size={'mdMedium'} >{`Assignee chosen is not part of ${taskPoolData?.selectedPool?.label}. Going ahead will reset the task pool. Continue?`}</Text>
                  <Button.Group style={{
                    marginTop: 12, display: 'flex',
                    justifyContent: 'flex-end',
                  }}>
                    <FoldButton
                      nativeProps={{
                        variant: BUTTON_TYPE.SECONDARY,
                        style: { margin: 3 },
                        onPress: () => {
                          setStateForHandlingPoolAfterTaskAssigneeChange({
                            showAlertOfEmptyingTaskPool: false,
                            closeSelect: true,
                            temporaryData: {}
                          })
                        },
                      }}
                      customProps={{
                        btnText: intl.formatMessage({
                          id: 'No',
                        }),
                        withRightBorder: false,
                      }}
                    ></FoldButton>
                    <FoldButton
                      nativeProps={{
                        variant: BUTTON_TYPE.PRIMARY,
                        style: { margin: 3 },
                        onPress: () => {
                          handleAssigneeChange(stateForHandlingPoolAfterTaskAssigneeChange?.temporaryData?.assignee, stateForHandlingPoolAfterTaskAssigneeChange?.temporaryData?.type)
                          setStateForHandlingPoolAfterTaskAssigneeChange({
                            showAlertOfEmptyingTaskPool: false,
                            closeSelect: true,
                            temporaryData: {}
                          })
                          setTaskPoolData(prev => {
                            return {
                              ...prev,
                              selectedPool: undefined,
                              selectedPoolUsers: [],
                              defaultAssignee: undefined,
                            }
                          });
                        },
                      }}
                      customProps={{
                        btnText: intl.formatMessage({
                          id: 'Yes',
                        }),
                        withRightBorder: false,
                      }}
                    ></FoldButton>
                  </Button.Group>
                </View>
              </Modal>
          </>
        )}
      </VStack>
      {componentState?.showMediaDrawer && (
        <MediaSelectionModal
          isOpen={true}
          onClose={() => {
            setComponentState((prev: any) => {
              return {
                ...prev,
                showMediaDrawer: false,
              };
            });
          }}
          onSelect={(mediaData) => {
            setComponentState((prev: any) => {
              return {
                ...prev,
                showMediaDrawer: false,
              };
            });
            const tempFileList = [
              {
                ...mediaData,
                source: TASK_ATTACHMENT_SOURCE.MEDIA,
                originFileObj: mediaData,
              },
            ];
            if (fileList?.length) {
              handleChange({
                ...value,
                fileList: uniqueArray([...(fileList || []), ...tempFileList]),
              });
            } else {
              handleChange({
                ...value,
                fileList: tempFileList,
              });
            }
          }}
        />
      )}
      </View>
      {isSidecarContext && showLinkedCarePlan && (
        <Drawer className='create-note-from-message-drawer' onClose={() => {}} width={'100%'} open>
          <CarePlan
            {...(carePlanProps as ICarePlanProps)}
            onClose={(isAdded: boolean) => {
              carePlanProps?.onClose?.(isAdded);
          }}/>
        </Drawer>
      )}
    </Drawer>
  );
};

export default withMiniContactViewHOC(AddOrUpdateTaskView);
